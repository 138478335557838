import React from "react"

import DataTable from "../../components/DataTable";
import {timestampToDateString} from "../../utils/datetimeUtils";
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal";
import TicketProductQuickActionButton from "../../components/TicketProductQuickActionButton";

import {PAYEE_PRODUCT_TYPE_TICKET} from "../../constants/payeeProducts"
const PayeeProductTable = ({
                          title = "Products",
                          payeeHandleId = "",
                          emptyText = "No Products",
                          payeeProductsArray = [],

                      }) => {
    return (
        <DataTable
            dataArray={payeeProductsArray}
            fields={[
                {
                    id: 'imageUrl',
                    header: 'Image',
                    transformFunction: imageUrl => <img src={imageUrl} width={80} maxHeight={80} />
                },
                {
                    id: 'name',
                    header: 'Name',
                },
                {
                    id: 'productType',
                    header: 'Type',
                    transformFunction: (productType, id, key, payeeProduct) =>   <div>
                                                            <div>{productType}</div>
                                                            {
                                                                productType === PAYEE_PRODUCT_TYPE_TICKET ? 
                                                                    <TicketProductQuickActionButton 
                                                                        payeeProduct={payeeProduct}
                                                                    /> 
                                                                :
                                                                ""
                                                            }
                                                        </div>
                },
                {
                    id: 'price',
                    header: "Price",
                    transformFunction: price => <div className="font-weight-600">EC$<AmountSmallAfterDecimal
                        amount={price}/></div>
                },
                {
                    id: 'createdAt',
                    header: 'Created At',
                    transformFunction: createdAt => timestampToDateString(createdAt, "EEE, dd MMMM 'at' t")
                },
                // {
                //     id: 'id', //quickAction
                //     header: "",
                //     hideSort: true,
                //     transformFunction: (id, key, i, invoice) => <InvoiceQuickActionButton invoice={invoice}
                //                                                                           payeeHandleId={payeeHandleId}/>
                // },
                // {
                //     id: 'id', //actions
                //     header: "",
                //     hideSort: true,
                //     transformFunction: (id, key, i, invoice) => <InvoiceActionsMenu invoice={invoice}
                //                                                                     iconHasShadow={false}
                //                                                                     payeeHandleId={payeeHandleId}/>
                // }
            ]}
            title={title}
            emptyText={emptyText}
            defaultSortBy="createdAt"
            searchFields={["name"]}
        />
    )
}

export default PayeeProductTable
import React from "react"

import {Helmet} from "react-helmet";

const Privacy = () => {
    return (
        <div>
            <Helmet>
                <title>Privacy Policy - Shopdm Pay </title>
            </Helmet>
            <div>
                <div className="h1">Privacy Policy</div>
            </div>
        </div>
    )
}

export default Privacy
import React from "react"
import {Outlet, Link, Navigate} from "react-router-dom"
import Navigation from "../../components/Navigation"
import Footer from "../../containers/Footer"

function LegalLayout ({}) {

    return (
        <div>
            <Navigation backgroundColor="var(--white)">
                <ul className="navMain">
                    <li>
                        <Link to="/"><button className="button transparent">Home</button></Link>
                    </li>
                </ul>
            </Navigation>
            <main className="columnLayout">
                <div className="padding-1em">
                <Outlet />    
                </div>
            </main>
            <Footer />
        </div>
    )
}


  export default LegalLayout
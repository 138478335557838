import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, onSnapshot, getDocs, query, collection, where, orderBy, limit, startAfter, endBefore, limitToLast} from "firebase/firestore"

import { logError } from "../utils/errorHandlingUtils"
// import { PAGINATE_NEXT, PAGINATE_BACK } from "../constants/interaction"

export const SAVE_PAYEE_PRODUCTS = 'SAVE_PAYEE_PRODUCTS'

export const savePayeeProducts = payeeProducts => {
    return {
        type: SAVE_PAYEE_PRODUCTS,
        payload: {
            payeeProducts
        }
    }
}

export const fetchSubscribeToPayeeProduct = (payeeProductId, onLoad=()=>{}) => {
    /**
      * Purpose: retrieve one payee product from the firestore database
      * Note: the onSnapshot below watches for changes to the payee product on the server
      */
    const firestore = getFirestore(firebaseApp)
    const payeeProductRef = doc(firestore, "payeeProducts", payeeProductId)
                                
    return async (dispatch) => {
        try {
            const payeeProductListener = await onSnapshot(payeeProductRef,
                docRef => {
                    if (!docRef.exists()) {
                        onLoad()
                        return
                    }
                    //get one payee product from the snapshot
                    const payeeProduct = {...docRef.data()}
                    dispatch(savePayeeProducts([payeeProduct]))
                    onLoad(payeeProduct)
                } 
            )
            return payeeProductListener
        } catch (e){
            const message = `action > payeeProducts > fetchSubscribeToPayeeProducts: Failed to subscribe to payee product`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

// export const fetchSavePayeesPaginatedPayeeProducts = (
//     payeeId,
//     firstDoc=null,
//     lastDoc=null,
//     paginateDirection=PAGINATE_NEXT,
//     pageNumber=0,
//     sortBy="createdAt",
//     sortDirection="desc",
//     productCount=30
//  ) => {
//     /**
//       * Purpose: - retrieve the payee's payeeProducts from the firestore database in batches of 30
//       *          - create successive queries that take the next or previous 30 products
//       */
//     const firestore = getFirestore(firebaseApp)

//     //1. form the base query which pulls payeeProducts for the specified payee
//     //and sorts them by most recent first 
//     let batchQuery = query(collection(firestore, "payeeProducts"),
//                                     where("payeeId", "==", payeeId),
//                                      orderBy(sortBy, sortDirection)
//                                      )
//     //2. add limit and document marker to the query, depending on whether
//     //   we are going forwards or backwards, and whether this is the first query
    
//     //offset limits are used when the side is loaded on the paginated page 
//     //and the page number is MORE than 0. Our response is to use limit to load all of the documents
//     //leading up to the desired page
//     let offsetLimit = 0
//     if (paginateDirection === PAGINATE_NEXT){
//         //(a) if forwards

//         //if this is not the first query, add the document marker as the last doc from the last query
//         if (lastDoc) batchQuery = query(batchQuery, startAfter(lastDoc))
//         //limit the query size
//         //if the page number is more than zero, but this is the first query
//         //e.g. if someone sends page 2 of a category to their friend
//         //we will need to load more products than we want to, since firebase's startAfter and endBefore methods force
//         //us to load from the beginning of the ordered collection
//         if (pageNumber > 0 && !lastDoc) {
//             offsetLimit = productCount * pageNumber
//             batchQuery = query(batchQuery, limit(offsetLimit + productCount))
//         } 
//         //limit to 30 docs or otherwise
//         else {
//             batchQuery = query(batchQuery, limit(productCount))
//         }
//     } else if (paginateDirection === PAGINATE_BACK && firstDoc) {
//         //(b) if backwards && we have a marker from a previous query
        
//         //use the last query's first doc as a marker, to get docs before that 
//         //and limit to 30 docs or otherwise
//         if (firstDoc) batchQuery = query(batchQuery, endBefore(firstDoc), limitToLast(productCount))
//     } 
//     else throw new Error(`pagination failed ${paginateDirection} is invalid or firstDoc or lastDoc error`)

//     return async (dispatch, getState) => {
//         try {
//             const {payeeProducts} = getState()
//             // //check whether this products were loaded recently, and if so, do not load again
//             /**
//             if (
//                 categories.loadedProductIdsByCategoryPage[categoryId] &&
//                 categories.loadedProductIdsByCategoryPage[categoryId][sortBy] &&
//                 categories.loadedProductIdsByCategoryPage[categoryId][sortBy][pageNumber]  
//             ) {
//                 const page = categories.loadedProductIdsByCategoryPage[categoryId][sortBy][pageNumber]
//                 return {
//                     success: true,
//                     productList: page.productIds.map(pId => products.productsById[pId]),
//                     firstDoc: page.firstDoc,
//                     lastDoc: page.lastDoc
//                 }
//             }
//             **/
//             const querySnapshot = await getDocs(batchQuery)

//             const loadedPayeeProducts = querySnapshot.docs
//                                                   //TODO handle mroe elegantly. 
//                                                   //This slice simply discards the extra loaded documents, causing them to the reloaded if the user goes to their pages
//                                                   //whereas, we could save them into redux if we calculate their firstDoc, lastDoc and pageNumbers and productIds 
//                                                   .slice(offsetLimit) 
//                                                   .map((docRef, i) => {
//                 //set document markers: first and last doc of the query
//                 if (i === 0) firstDoc = docRef
//                 if (i === querySnapshot.docs.length - 1) lastDoc = docRef
//                 //extract and format the product data
//                 const payeeProduct = { ...docRef.data()}
//                 return payeeProduct
//             });
//             //only save the loaded payee products if some payee products are returned
//             //this avoids internet and other loading errors
//             if (loadedPayeeProducts.length === 0) return {success: false}
//             dispatch(
//                 savePayeeProducts(loadedPayeeProducts)
//             )
//             // dispatch (
//             //     savePayeeProductsPage(
//             //         categoryId,
//             //         sortBy,
//             //         pageNumber,
//             //         categoryProducts.map(p => p.id),
//             //         firstDoc,
//             //         lastDoc
//             //     )
//             // )
//             return {
//                 success: true,
//                 objectList: loadedPayeeProducts,
//                 firstDoc,
//                 lastDoc
//             }
//         } catch (e){
//             const message = `action > products > fetchSavePayeesPaginatedPayeeProducts: Failed to save paginated payee products for payee ${payeeId}`
//             if (e.message_){
//                 //deal with firebase-specific errors
//                 logError(new Error(`${e.message} ${message}`))
//             } else {
//                 e.message = `${e.message} ${message}`
//                 logError(e)
//             }
//             return {
//                 success: false
//             }
//         }
//     }
// }

export const fetchSavePayeeProductsForPayee = (
    payeeId,
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeProductsRef = query(
                            collection(firestore, "payeeProducts"), 
                            where("payeeId", "==", payeeId)
                        )
    return async (dispatch) => {
        try{
            const querySnapshot = await getDocs(payeeProductsRef)
            //get an array of payee activity objects from the snapshot
            const payeeProducts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(savePayeeProducts(payeeProducts))
            return true
        } catch (e){
            const message = `action > payeeProducts > fetchSavePayeeProductsForPayee: Failed to save payee products for ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}
import React from "react";

import Form from "../../components/Form";
import FormField from "../../components/FormField"

import {capitalizeAllWords} from "../../utils/stringUtils"
import {validateAddress, isValidEmail, isValidLegalName, handleChangeZipCode} from "../../utils/formValidationUtils"
import { COUNTRY_CODES } from "../../constants/country"
import {matchIsValidTel} from 'mui-tel-input'

import {connect} from "react-redux"

class CustomerForm extends Form{

    constructor(props){
        super(props)
        this.state = {
            values: {...this.fields, ...props.defaultValues},
            errors: {},
        }
    }

    fields = {
        name: "",
        phoneNumber: "+1767",
        address: {
            city: "Roseau",
            line1: "",
            zipCode: "",
            countryName: "Dominica",
            countryCode: "DM",
        },
        email: "",
    }

    static defaultProps = {
        title: "Customer Form",
        buttonText: 'Save',
        onSubmit: ()=>{},
        defaultValues: {}
    }

    executeSubmit = async () => {
        const {onSubmit, actions} = this.props
        const {name, phoneNumber, address, email}= this.state.values
        return await onSubmit(
            capitalizeAllWords(name.trim()),
            phoneNumber.trim(),
            address,
            email.trim(),
            this.handleSubmitSuccess, 
            this.handleSubmitError
        )
    }

    validate = () => {
        const errors = {}
        const {values} = this.state
        let {name, phoneNumber, address, email} = values
        const {payeeCustomers, payeeId} = this.props
        name = name.trim()
        phoneNumber = phoneNumber.trim()
        Object.keys(values).forEach(key => {
            if (!Boolean(values[key]) &&
                    key !== "email" 
                ){
                const label = key === "phoneNumber" ? "Phone number" : capitalizeAllWords(key)
                errors[key] = `${label} is required`
            }
        })
        if (!errors["name"] && !isValidLegalName(name)) errors['name'] = 'Name is invalid'
        if (!errors["phoneNumber"] && !matchIsValidTel(phoneNumber)) errors['phoneNumber'] = 'Phone number is invalid'
        if (!errors["phoneNumber"]){
            const previousCustomer = Object.values(payeeCustomers.payeeCustomersById)
                                           .filter(c => c.payeeId === payeeId)
                                           .find(c => c.phoneNumber === phoneNumber)
            if (previousCustomer) errors["phoneNumber"] = `This phone number has already been used by ${previousCustomer.name}`
        }
        const addressErrors = validateAddress(address)
        if (Object.keys(addressErrors).length > 0){
            errors["address"] = Object.values(addressErrors)[0]
            errors.addressErrorObject = addressErrors
        }
        //if the email is set, ensure it is valid
        if (email){
            if (!isValidEmail(email)) errors.email = "Email is invalid"
        }
        if (Object.values(errors).every(error => !error)) return true
        else {
            this.setState({errors})
            return false
        }
    }

    handleSubmitSuccess = customer => {
        const {onSuccess=()=>{}} = this.props
        onSuccess(customer.id)
    }
    
    handleChangePhoneNumber = (value, phoneInfo={}) => {
        //if the user changes the flag, and +1767 was selected as the default
        const {phoneNumber=""} = this.state.values
        const address = {...this.state.values.address}
        if (
            phoneNumber === "+1767" &&
            value !== "+1767" &&
            String(value).match(/\+(?!1)\d{1,3}767/)
        ){
            //remove the 767 behind the area code of the new flag
            if (value.length > 3) value = value.slice(0, -3)
        }
        //get the country code as indicated by the phone's flag input
        const {countryCode=""} = phoneInfo || {}
        const {name:countryName=""} = COUNTRY_CODES[countryCode] || {}
        //if the country of the phone's flag input does not match
        //the currently selected country in the address, then update it
        if (
            countryCode &&
            countryName &&
            address.countryCode !== countryCode
        ){
            if (
                address.countryCode === "DM" &&
                address.city.toLowerCase() === "roseau"
            ){
                address.city = ""
            }
            address.countryCode = countryCode
            address.countryName = countryName
        }
        
        this.setState({
            values: {
                ...this.state.values,
                phoneNumber: value,
                address
            },
            errors: {}
        })
    }

    handleChangeAddress = value => {
        //if the user changes the city of their address, clear Roseau as the default city
        const {address={}} = this.state.values
        if (
            address.countryCode === "DM" &&
            String(address.city).toLowerCase() === "roseau" &&
            value.countryCode !== "DM" &&
            String(value.city).toLowerCase() === "roseau"
         ){
             value.city = ""
         } 
         //if the user changes the zip code
         else if (value.zipCode !== address.zipCode){
            const zipCode = handleChangeZipCode(value.zipCode)
            //do not allow invalid changes
            if (zipCode === undefined) value.zipCode = address.zipCode
            else value.zipCode = zipCode
         }
        this.setState({
            values: {
                ...this.state.values,
                address: value
            },
            errors: {}
        })
    }

    render(){
        const {values, errors} = this.state
        const {name, phoneNumber, address, email} = values
        const {title, buttonText} = this.props
        return (
            <div>
                <div className="h2 text-align-center">{title}</div>
                <FormField 
                    value={name} 
                    label="Full Name"
                    onChange={e => this.handleChange(e, "name")}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.name}
                />
                <FormField 
                    label="Phone Number"
                    type="tel" 
                    value={phoneNumber}
                    onChange={this.handleChangePhoneNumber} 
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.phoneNumber}
                />
                <FormField 
                    label="Billing Address (must match customer's bank card)"
                    type="address" 
                    value={address}
                    onChange={address => this.handleChangeAddress(address)} 
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.address}
                    errorObject={errors.addressErrorObject}
                />
                <FormField 
                    label="Email (optional)"
                    value={email} 
                    onChange={e => this.handleChange(e, "email")}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.email}
                />
                <button className="button secondary width-100pct" onClick={this.handleSubmit}>{buttonText}</button>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    payeeCustomers: state.payeeCustomers,
})

export default connect(mapStateToProps)(CustomerForm)
import React from "react"
import styles from "./Main.module.css"

import {Helmet} from "react-helmet";

import PayerReceiptDateList from "../../containers/PayerReceiptDateList";

import {connect} from "react-redux"
import {Link} from "react-router-dom"

const Main = ({receipts}) => {
    return (
        <div className={styles.container}>
            <Helmet>
                <title>Shopdm Pay</title>
            </Helmet>
            <section className={`section ${styles.heroSection}`}>
                <div>
                    <div className="hero">
                        <div className="heroText">accept card payments worldwide from Dominica</div>
                        <div className="h2">Share a link. Get paid.</div>
                        <div className="margin-top-05em">
                            <Link target="_blank" to="https://docs.google.com/forms/d/e/1FAIpQLSfb48bE5w_zfE62x9GjbnYif7oyQfZGUOiCy5SWDQCNwbSUow/viewform?usp=sf_link">
                                <button className="button secondary">
                                    Interested? Sign Up Here
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {
                // Object.keys(receipts.receiptsById).length > 0 ?
                false ? //hiding due to bad user feedback, TODO revisit
                    <section className={`section ${styles.activitySection}`}>
                        <div className={`${styles.receiptList}`}>
                            <div className={`card`}>
                                <PayerReceiptDateList 
                                    title="Your Recent Receipts"
                                />
                            </div>
                        </div>
                    </section>
                :
                ""
            }
        </div>
    )
}

const mapStateToProps = state => ({
    receipts: state.receipts
})

export default connect(mapStateToProps)(Main)
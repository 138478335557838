import {firebaseApp, baseUrl} from "../config/firebase"
import { getFirestore, doc, getDoc, getDocs, onSnapshot, updateDoc, serverTimestamp, query, collection, where } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"

export const SAVE_RECEIPTS = 'SAVE_RECEIPTS'
export const SAVE_RECEIPTS_FOR_PAYEE_PRODUCT = "SAVE_RECEIPTS_FOR_PAYEE_PRODUCT"
export const VERIFY_RECEIPT = "VERIFY_RECEIPT"
export const SET_LAST_RECEIPT_EMAIL = 'SET_LAST_RECEIPT_EMAIL'

export const saveReceipts = receipts => {
    return {
        type: SAVE_RECEIPTS,
        payload: {
            receipts
        }
    }
}

export const saveReceiptsForPayeeProduct = (
    receipts,
    payeeProductId,
    updateLastLoaded=false
) => {
    return {
        type: SAVE_RECEIPTS_FOR_PAYEE_PRODUCT,
        payload: {
            receipts,
            payeeProductId,
            updateLastLoaded
        }
    }
}

export const verifyReceipt = (
    receiptId, 
    variantId,
    verification
) => {
    return {
        type: VERIFY_RECEIPT,
        payload: {
            receiptId,
            variantId,
            verification
        }
    }
}
export const setLastReceiptEmail = lastReceiptEmail => {
    return {
        type: SET_LAST_RECEIPT_EMAIL,
        payload: {
            lastReceiptEmail
        }
    }
}

export const fetchSaveReceipt = (receiptId) => {
    /**
      * Purpose: retrieve one receipt from the firestore database
      */
    const firestore = getFirestore(firebaseApp)
    const receiptRef = doc(firestore, "receipts", receiptId)
                                
    return async (dispatch) => {
        try {
            const docRef = await getDoc(receiptRef)
            const receipt = docRef.exists() ? {...docRef.data()} : null
             if (receipt) dispatch(saveReceipts([receipt]))
            return receipt
        } catch (e){
            const message = `action > receipts > fetchSaveReceipt: Failed to save receipt ${receiptId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveReceiptsForPayeeProduct = (payeeProductId, payeeId) => {
    /**
      * Purpose: retrieve one receipt from the firestore database
      */

    const firestore = getFirestore(firebaseApp)
    const receiptsRef = query(collection(firestore, "receipts"),
                            where("payeeId", "==", payeeId),
                            //TODO - add payeeProductIds to each receipt, and store the payee product ids from the line items in it, to allow quick querying
                            // where("payeeProductIds", "array-contains", payeeProductId),
                            
                            )
                                
    return async (dispatch) => {
        try {
            const querySnapshot = await getDocs(receiptsRef)
            //get an array of receipt objects from the snapshot
            const receipts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(saveReceiptsForPayeeProduct(receipts, payeeProductId , true))
            return true
        } catch (e){
            const message = `action > receipts > fetchSaveReceiptsForPayeeProduct: Failed to receipts for payeeProduct ${payeeProductId} and payee ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToCreatedReceiptsForPayeeProduct = (
    payeeProductId,
    payeeId,
    fromDate = Date.now()
 ) => {
    const firestore = getFirestore(firebaseApp)
    const receiptsRef = query(
                            collection(firestore, "receipts"), 
                            where("payeeId", "==", payeeId), 
                            //TODO - add payeeProductIds to each receipt, and store the payee product ids from the line items in it, to allow quick querying
                            // where("payeeProductIds", "array-contains", payeeProductId),
                            //TODO had problems getting updates on mobile,
                            //suspect it is due to differences in the clock, so I just removed this
                            //will fix later
                            // where("createdAt", ">=",  fromDate)
                        )
    return async (dispatch) => {
        try{
            const receiptsListener = await onSnapshot(receiptsRef,
                querySnapshot =>{
                    //get an array of payee activity from the snapshot
                    const receipts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    //do not update last loaded
                    dispatch(saveReceiptsForPayeeProduct(receipts, payeeProductId))
                }
            )
            return receiptsListener
        } catch (e){
            const message = `action > receipts > fetchSubscribeToCreatedReceiptsForPayeeProduct: Failed to save newly created receipts for ${payeeId} and payeeProduct ${payeeProductId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSubscribeToUpdatedReceiptsForPayeeProduct = (
    payeeProductId,
    payeeId,
    fromDate = Date.now()
 ) => {
    const firestore = getFirestore(firebaseApp)
    const receiptsRef = query(
                            collection(firestore, "receipts"), 
                            where("payeeId", "==", payeeId), 
                            //TODO - add payeeProductIds to each receipt, and store the payee product ids from the line items in it, to allow quick querying
                            // where("payeeProductIds", "array-contains", payeeProductId),
                            where("lastUpdatedAtTimestampt", ">",  new Date(fromDate))
                        )
    return async (dispatch) => {
        try{
            const receiptsListener = await onSnapshot(receiptsRef,
                querySnapshot =>{
                    //get an array of payee activity from the snapshot
                    const receipts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    //do not update last loaded
                    dispatch(saveReceiptsForPayeeProduct(receipts, payeeProductId))
                }
            )
            return receiptsListener
        } catch (e){
            const message = `action > receipts > fetchSubscribeToUpdatedReceiptsForPayeeProduct: Failed to save newly updated receipts for ${payeeId} and payeeProduct ${payeeProductId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchVerifyReceipt = (receiptId, variantId, verificationId) => {
    /**
      * Purpose: mark a receipt as verified
      */

    const firestore = getFirestore(firebaseApp)
    const receiptRef = doc(firestore, "receipts", receiptId)
                                
    return async (dispatch, getState) => {
        try {
            const {user} = getState()
            const verification = {
                id: verificationId,
                verifiedByUserId: user.id,
                verifiedAt: Date.now(),
                quantity: 1
            }
            updateDoc(receiptRef, {
                [`lineItemsById.${variantId}.verificationsById.${verification.id}`]: verification,
                lastUpdatedAtTimestamp: serverTimestamp(),
                lastUpdatedAt: Date.now()
            })
            dispatch(verifyReceipt(receiptId, variantId, verification))
            return true
        } catch (e){
            const message = `action > receipts > fetchVerifyReceipt: Failed to verify receipt ${receiptId} for product variant ${variantId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchEmailReceipt = (
    email,
    receiptId,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    return async (dispatch, getState) => {
        try {
            const {device} = getState()
            const link = `${baseUrl}v1/receipts/email`
            const response = await fetch(link, {
                "method": 'POST',
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                "body": JSON.stringify({
                    email,
                    receiptId,
                    deviceId: device.id
                })
            })
            if (!response.ok) {
                throw new Error("something went wrong")
            }
            dispatch(setLastReceiptEmail(email))
            onSuccess(email)
            return true
        } catch (e) {
            e.message = `${e.message}`
            logError(e)
            onError(e)
            return false
        }
    }
}
import React, {useState} from "react"

import {Helmet} from "react-helmet";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMore from '@mui/icons-material/ExpandMore';

const FAQ = () => {
    const [expanded, setExpanded] = useState(0);

  const handleChange = (panelId) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelId : false);
  };
    return (
        <div>
            <Helmet>
                <title>FAQs - Shopdm Pay </title>
            </Helmet>
            <main className="columnLayout text-align-justify">
                <div className="padding-1em">
                    <div className="h1">Frequently Asked Questions</div>
                    <div className="margin-top-1em">
                        <Accordion 
                            className="accordion"
                            expanded={expanded === 0}
                            onChange={handleChange(0)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel0-content"
                                id="panel0-header"
                                className="font-weight-600 accordionSummary secondary"
                            >
                                What is Shopdm Pay and how does it work?
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>Not being able to accept card payments has been holding back Dominican businesses for too long.</div>
                                <div className="margin-top-05em">Shopdm Pay is a card payment solution built for Dominican businesses by a Dominican business.</div>
                                <div className="margin-top-05em"> We let you accept VISA or Mastercard payments from customers around the world. You can send payment links, accept payments via QR codes and integrate payments on your website.</div>
                                <div className="margin-top-05em">We can set you up in less than 24 hours. We're cheaper than most other solutions and we've got world-class technology.</div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            className="accordion"
                            expanded={expanded === 1}
                            onChange={handleChange(1)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="font-weight-600 accordionSummary secondary"
                            >
                                How does Shopdm Pay help my business? I just want to accept cards
                            </AccordionSummary>
                            <AccordionDetails>
                                We offer 4 ways for you to accept cards:
                                <ol> 
                                    <li>
                                        <b>Online Invoice Payments:</b> Maybe you do software contracting, photography or another service for clients overseas. Maybe you export products, or you're a doctor, or you rent apartments or maybe you just need to collect money from people who won't pay. This payment-link invoicing solution is for you, <a href="https://youtu.be/ozm4_J937o0" target="_blank" noreferrer>see here</a>. 
                                    </li>
                                    <li><b>In-Person QR Code Payments:</b> Got a store where you serve customers? This is for you, print as many QR codes as you need and accept card payments everywhere.</li>
                                    <li><b>Hosted Product Payments:</b> Sell tickets? Or do you sell other products or services with fixed prices? Just set up a product payment link and let your customers pay you.</li>
                                    <li><b>Integrated Website Payments:</b> (Coming Soon) We know, we know, Stripe is not available in your country. We will soon launch our website integration tools to help you get paid on your website.</li>
                                </ol>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            className="accordion"
                            expanded={expanded === 2}
                            onChange={handleChange(2)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                className="font-weight-600 accordionSummary secondary"
                            >
                                What are the fees? 
                            </AccordionSummary>
                            <AccordionDetails>
                                We charge a platform fee on each transaction. That's it.
                                <ul>
                                    <li><b>(Standard) 3% paid by you and 3% paid by your customer:</b> e.g. you bill your customer $100. They pay $103, you get $97.</li>
                                    <li><b>OR 6% paid by you:</b> e.g. you bill your customer $100. They pay $100, you get $94. </li>
                                    <li><b>OR 6% paid by your customer:</b> e.g. you bill your customer $100. They pay $106, you get $100. </li>
                                </ul>
                                <div>You choose your fee structure and there are no other fees on the usual plan.</div>
                                <div>Fees should by simple, so we've kept them simple.</div>
                                <div className="margin-top-05em">Just so you understand, <u>here are all of the things we DONT do</u>.</div>
                                <ul>
                                    <li><b>No monthly fees:</b> We make money when you make money. There are no fixed fees for Merchants on our standard partner plan.</li>
                                    <li><b>No fixed transaction fees:</b> We only charge our platform fee, we don't charge a fixed transaction fee. A fixed US$0.30 is almost 10% of an EC$10 product, our solution will save you lots of money if you sell cheaper products. </li>
                                    <li><b>No currency conversion fees:</b> We only accept EC dollars. All other popular payment acceptance methods (Stripe, Paypal, Payoneer) force you to accept USD instead of EC dollars. This means you pay an additional 1% to 2.7% to convert the money to EC after you've paid 3% to 6% to receive it.</li>
                                    <li><b>No transfer fees:</b> We transfer your funds to your account weekly, for free. Transfer fees on other platforms can add up fast. For example, if you pay $20 for a wire transfer from Airbnb to Dominica, that is an addtional 2% on $1000, you should think of this as part of their percentage fee.</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            className="accordion"
                            expanded={expanded === 3}
                            onChange={handleChange(3)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                                className="font-weight-600 accordionSummary secondary"
                            >
                                How does the money get into my account?
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>All you need to accept cards is an NBD bank account. That's it.</div>
                                <ul>
                                    <li>As soon as customers pay you, your Merchant Account balance goes up, automatically in real-time.</li>
                                    <li>We pay your balance into your NBD account, automatically on a weekly schedule.</li>
                                    <li>We do payouts on the 1st, 8th, 15th and 22nd of every month. We're trying to introduce more reliability and predictability into Dominican business culture, so we take this very seriously.</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            className="accordion"
                            expanded={expanded === 4}
                            onChange={handleChange(4)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                                className="font-weight-600 accordionSummary secondary"
                            >
                                Is it safe and secure?
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>We use industry-standard security. No service on the internet can be 100% safe, but we've built this platform in a security-first manner.</div>
                                <ol>
                                    <li><b>Cardholder-Information</b>: We never handle card information directly. We do not accept, transmit or store card information.  Instead, we wholly outsource the handling of your card information to specialized and highly secure payment processing companies, each of which have over 15 years of experience. We have integrated their technology onto our website to give your customers a seamless experience, but our card-information security protocol is to rely on the banks' and their processors' security rather than attempt to build our own. </li>
                                    <li><b>Encryption During Transmission</b>: We encrypt all of your personal and transaction data during transmission using Secure Sockets Layer (SSL) technology to ensure that your Personally Identifiable Information is safe as it is transmitted.</li>
                                    <li><b>Industry Standard Database Security & Encryption at Rest</b>: We store all of your personal and transaction information securely on Google Cloud's servers in the USA. Due to this, the data you store on Shopdm Pay is encrypted at rest and enjoys very high levels of security.</li>
                                    <li><b>Password Protection</b>: We use industry standard password protection to limit access to your profile and data. You are responsible for keeping your password confidential. We advise you not to share your password with anyone.</li>
                                    <li><b>Security-focussed Development</b>: Throughout our development process we've taken every effort to minimize attack vectors from bad actors. These include keeping our APIs closed by default, and taking actions to avoid XSS, DDOS and phishing attacks. Unfortunately, the transmission of information via the internet is never completely secure. Although we take steps to protect your information, we cannot guarantee the security of your data transmitted to the Platform; any transmission is at your own risk. Once we have received your information, we use strict procedures and security features to try to prevent unauthorised access.</li>
                                </ol>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            className="accordion"
                            expanded={expanded === 5}
                            onChange={handleChange(5)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                                className="font-weight-600 accordionSummary secondary"
                            >
                                Is it easy to use?
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>It's super easy to use. You'll be pleasantly suprised if you haven't used it yet. We're pulling Dominica into the future.</div>
                                <div className="margin-top-05em">You and your employees can make invoices with a few clicks. Just input how much and what it's for.</div>
                                <div className="margin-top-05em">If your customer is paying you for the first time, you'll need to collect their name and other basic info to stay AML compliant. But it will be done in seconds.</div>
                                <div className="margin-top-05em">With QR-code payments, you don't need to do anything. Your customers just scan and pay.</div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            className="accordion"
                            expanded={expanded === 6}
                            onChange={handleChange(6)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                                className="font-weight-600 accordionSummary secondary"
                            >
                                Sounds great, how do I sign up?
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>You can get set up with us in 24 hours. As long as you have a legitimate business, you are in one of our supported industries, you're based in Dominica, and you have an NBD bank account, you should be good to go.</div>
                                <div className="margin-top-05em">Our signup process:</div>
                                <ol>
                                    <li>Tell us you're interested in signing up <a href="https://docs.google.com/forms/d/e/1FAIpQLSfb48bE5w_zfE62x9GjbnYif7oyQfZGUOiCy5SWDQCNwbSUow/viewform?usp=sf_link" target="_blank" rel="noreferrer">here.</a></li>
                                    <li>We'll schedule a time to call you and/or visit your business in person.</li>
                                    <li>
                                        <div>Have your personal and business documents on hand. Local AML and KYC laws require us to collect:</div>
                                        <ol>
                                            <li>
                                                For each director or partner or owner with more than 10% ownership:
                                                <ol className="list-style-lower-alpha">
                                                    <li>a Passport (or 2 other forms of government ID) and</li>
                                                    <li>a proof of address e.g. driver’s license or utility bill.</li>
                                                </ol>
                                            </li>
                                            <li>
                                                Your business registration or incorporation documents:
                                                <ol className="list-style-lower-alpha">
                                                    <li>For sole traders or professionals without registered businesses: We'll need to establish that you have a legit business with info like your sales data, bank records etc.</li>
                                                    <li>For registered businesses or partnerships: We'll collect your Business Registration Certificate</li>
                                                    <li>For incorporated companies: We'll collect your Certificate of Incorporation and your Articles of Incorporation</li>
                                                    <li>For Charities and NGOs: We'll need your Certificate of Incorporation, Articles of Incorporation and FSU Certificate. Our vetting partner Island Impact will do some additional checks.</li>
                                                </ol>
                                            </li>
                                            <li>We're a financial instituion, so we may need to collect additional documentation to stay compliant with the local laws. We'll only ever take what we need and we'll always keep it confidential.</li>
                                        </ol>
                                    </li>
                                    <li>You'll also need a soft copy of your logo and your NBD bank account details.</li>
                                    <li>In many cases, your Merchant Account will be set up right there on the call. If we need more information or if we're not a good fit for you, we'll let you know.</li>
                                </ol>
                            </AccordionDetails>
                        </Accordion>
                        
                    </div>
                    
                    
                </div>
            </main>
            
        </div>
    )
}

export default FAQ
import { PAYEE_PRODUCT_TYPE_TICKET } from "../constants/payeeProducts";
import { QRCODE_END_OF_FILE } from "../constants/qrCodes"
import {RESULT_SUCCESS, RESULT_WARNING, RESULT_ERROR} from "../constants/results"

export const getReceiptLink = (receiptId, payeeHandleId="") => {
    return `${window.location.origin}/${payeeHandleId}/r/${receiptId}`
}

export const getTicketReceiptQRCodeData = (lineItemsById={}, payeeId="", receiptId="") => {
    let qrData = ""
    Object.values(lineItemsById).forEach(lineItem => {
        lineItem = {...lineItem, payeeId, receiptId}
        if (lineItem.productType !== PAYEE_PRODUCT_TYPE_TICKET) return ""
        //remove unnecessary fields
        delete lineItem.imageUrl
        delete lineItem.productType
        delete lineItem.quantityInStock
        delete lineItem.hasLimitedStock
        qrData = `${qrData}${JSON.stringify(lineItem)}${QRCODE_END_OF_FILE}`
    })
    return qrData
}

export const decodeTicketReceiptQRCodeData = (qrString="") => {
    //if not string return
    if (!qrString || typeof qrString !== 'string') return null
    //if string does not contain the QR code end of file marker, return
    if (!qrString.endsWith(QRCODE_END_OF_FILE)) return null
    //remove the end of file marker
    qrString = qrString.replace(QRCODE_END_OF_FILE, "")
    return JSON.parse(qrString) 
}

export const verifyReceiptScanData = (receiptScanData={}, receipts={}, currentPayeeId, currentPayeeProductId) => {
    /**
     * Purpose: Verify that the data on a QR receipt matches the receipt in the DB and that it is valid 
     * 
     * Method
     * 1. Is the receipt valid? Successfully retrieve the receipt and verify the incoming with the saved payeeId, payeeProductId and variantId
     * 2. Is the receipt relevant? Ensure it has the product Id we are checking and us as payee
     * 3. Has the receipt been used up? verified more times than the quantity bought 
     */
    const results = {success: false}
    //1. is the receipt valid?
    const {receiptId=""} = receiptScanData
    const receipt = receipts.payeeProductReceiptsById[receiptId]
    //no such receipt
    if (!receipt) {
        results.result = RESULT_ERROR
        results.resultMessage = "Receipt Not found"
        return results
    }
    const lineItem = receipt.lineItemsById[receiptScanData.id]
    if (
        receiptScanData.payeeId !== receipt.payeeId || //wrong payee
        //no variant Id match
        !lineItem || receiptScanData.id !== lineItem.id ||
        //no product match
        receiptScanData.payeeProductId !== lineItem.payeeProductId
    ){
        results.result = RESULT_ERROR
        results.resultMessage = "Invalid data"
        return results
    }
    //2. is the receipt relevant to the current product and payee?
    if (receiptScanData.payeeId !== currentPayeeId){//not the current payee
        results.result = RESULT_WARNING
        results.resultMessage = "Wrong payee"
        return results
    }
    if (receiptScanData.payeeProductId !== currentPayeeProductId){ //not the current product
        results.result = RESULT_WARNING
        results.resultMessage = "Wrong product"
        return results
    }
    //3. Has the receipt been used up
    const {verificationsById={}, quantity} = lineItem
    //TODO, may need to change this if we make it possible to verify more than one qty at once, per scan
    //e.g. letting 2 people into an event and saying "admit 2"
    const numOfVerifications = Object.keys(verificationsById).length
    if (numOfVerifications >= quantity) {
        results.result = RESULT_WARNING
        results.resultMessage = "Already used"
        return results
    }
    //if everything is good, it is verified successfully
    const resultMessage = quantity > 1 ? `All good: ${numOfVerifications + 1} used out of ${quantity}`
                          : "All good!"
    return {
        success: true,
        resultMessage,
        result: RESULT_SUCCESS,
        // Used a deterministic Id to avoid the possibility of using up more than one of a ticket's scans at once
        verificationId: `${numOfVerifications}${receiptId}${lineItem.id}`
    }
}
import React from "react"

import {Helmet} from "react-helmet";

const Security = () => {
    return (
        <div>
            <Helmet>
                <title>Security Policy - Shopdm Pay </title>
            </Helmet>
            <div>
                <div className="h1">Security Policy</div>
            </div>
        </div>
    )
}

export default Security
import {v4 as uuid4} from 'uuid'
import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, writeBatch, setDoc} from "firebase/firestore";
import {isMobileOnly, isBrowser, isIOS, isAndroid} from "react-device-detect"
import {logError} from "../utils/errorHandlingUtils"
import { getDateString } from "../utils/datetimeUtils"
import {token} from "../config/ipinfo"
import {WEEK_IN_MILLISECONDS} from "../constants/datetime"

export const CREATE_DEVICE = 'CREATE_DEVICE'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'

export const createDevice = device => {
    return {
        type: CREATE_DEVICE,
        payload: {
            device
        }
    }
}

export const updateDevice = deviceUpdate => {
    return {
        type: UPDATE_DEVICE,
        payload: {
            deviceUpdate
        }
    }
}

const fetchIPInformation = async () => {
    try {
        const p = await fetch(`https://ipinfo.io?token=${token}`)
        return await p.json()
    } catch (e){
        const message = `action > devices > fetchIPInformation: Failed to fetch IP Information`
        if (e.message_){
            //deal with firebase-specific errors
            logError(new Error(`${e.message} ${message}`))
        } else {
            e.message = `${e.message} ${message}`
            logError(e)
        }
        return {error: true}
    }
}

export const fetchCreateDevice = (onSuccess=()=>{}, onError=()=>{}) => {
    const id = uuid4()
    const firestore = getFirestore(firebaseApp)
    const devicesRef = doc(firestore, "devices", id)
    let device = {id}
    return async (dispatch, getState) => {
        try{
            const ipInfo = await fetchIPInformation()
            if (!ipInfo.error){
                const  {city="", country="", ip="", loc="", org="", region="", timezone=""} = ipInfo
                device = {
                    id,
                    cityName: city ? city.toLowerCase() : "",
                    latLon: loc,
                    timezone,
                    ip,
                    org,
                    region,
                    countryCode: country ? country.toLowerCase() : "",
                    lastUpdatedIPInfo: Date.now()
                }
            }
            const {user} = getState()
            const deviceType = isMobileOnly ? "mobile" : isBrowser ? "desktop" : "tablet"
            const userAgent = window && window.navigator ? window.navigator.userAgent : ""
            //separate building of the device object as there may be internet issues
            device = {
                ...device,
                deviceType,
                lastSignedInUserId: user.id ? user.id : null,
                mobileOSType: isIOS ? "ios" : isAndroid ? "android" : "not mobile",
                firstVisitedAt: Date.now(),
                firstVisitDate: getDateString(),
                lastVisitedAt: Date.now(),
                lastVisitDate: getDateString(),
                totalVisits: 1,
                userAgent,
                createdOnPlatform: "PAYDM"
            }
            const batch = writeBatch(firestore)
            batch.set(devicesRef, device)
            //if a user was signed in on this device, add it to their list of devices
            if (user.id){
                const userRef = doc(firestore, "users", user.id)
                batch.set(userRef, {
                    devices: {[id]: {id, deviceType}}
                }, {merge: true})
            }
            await batch.commit()
            dispatch(createDevice(device))
            onSuccess(device)
            return true
        } catch (e){
            const message = `action > devices > fetchCreateDevice: Failed to create device ${JSON.stringify(device)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(device)
            return false
        } 
    }
}

export const fetchUpdateDevice = (id, onSuccess=()=>{}, onError=()=>{}) => {
    const firestore = getFirestore(firebaseApp)
    const devicesRef = doc(firestore, "devices", id)
    let deviceUpdate = {}
    return async (dispatch, getState) => {
        try{
            const {user, device} = getState()
            let ipInfo = {}
            if (!device.lastUpdatedIPInfo || ((Date.now() - device.lastUpdatedIPInfo) > WEEK_IN_MILLISECONDS)){
                //if a week has passed since the last update to ip information
                const ipInfoFromApi = await fetchIPInformation()
                if (!ipInfoFromApi.error){
                    const  {city="", country="", ip="", loc="", org="", region="", timezone=""} = ipInfoFromApi
                    ipInfo = {
                        cityName: city ? city.toLowerCase() : "",
                        latLon: loc,
                        ip,
                        timezone,
                        region,
                        org,
                        countryCode: country ? country.toLowerCase() : "",
                        lastUpdatedIPInfo: Date.now()
                    }
                }
            }
            const userAgent = device.userAgent ? device.userAgent : window && window.navigator ? window.navigator.userAgent : ""
            deviceUpdate = {
                lastSignedInUserId: user.id ? user.id : device.lastSignedInUserId,
                lastVisitedAt: Date.now(),
                lastVisitDate: getDateString(),
                totalVisits: device.totalVisits + 1,
                userAgent,
                ...ipInfo
            }
            await setDoc(devicesRef, {
                ...device,
                ...deviceUpdate
            }, 
            {merge: true})
            dispatch(updateDevice(deviceUpdate))
            onSuccess(deviceUpdate)
            return true
        } catch (e){
            const message = `action > devices > fetchUpdateDevice: Failed to update device ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(deviceUpdate)
            return false
        } 
    }
}
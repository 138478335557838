import React, { useState } from "react"
import ResultModal from "../components/ResultModal"
import {RESULT_SUCCESS} from "../constants/results"

/**
 * Purpose:
 * 
 * - easily display the result modal on a component 
 * - all of the params necessary to display the result must be passed in
 * - the component can then use of setResult to set the result
 * 
 */
const withResultModal = (Component) => {
    return function WithResultModal (props) {
        const [result, setResult] = useState(false)
        const [resultMessage, setResultMessage] = useState("")
        const [resultOptions, setResultOptions] = useState({})
        const {autoCloseTimeout=2000, autoCloseOnSuccess=true, onClose=()=>{}} = resultOptions
        return (
            <React.Fragment>
                <Component 
                    {...props}
                    setResult={setResult}
                    setResultMessage={setResultMessage}
                    setResultOptions={setResultOptions}
                />
                {
                    result ?
                    <ResultModal 
                        result={result}
                        autoClose={autoCloseOnSuccess && result === RESULT_SUCCESS}
                        message={resultMessage}
                        onClose={() => {
                            setResult(false)
                            setResultMessage("")
                            setResultOptions({})
                            onClose()
                        }}
                        autoCloseTimeout={autoCloseTimeout}
                    />
                    :
                    null
                }
            </React.Fragment>
        )

    }
}

export default withResultModal
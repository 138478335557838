import React, {useState} from "react"

import {PAYEE_PRODUCT_TYPE_TICKET} from "../../constants/payeeProducts"
import Modal from "../../components/Modal"
import TicketEntryScanner from "../../containers/TicketEntryScanner"

const TicketProductQuickActionButton = ({payeeProduct={}}) => {
    const {productType="", payeeId=""} = payeeProduct;
    const [modalOpen, setModalOpen] = useState(false)

    if (!payeeProduct.id) return ""
    //ensure the product is a ticket
    if (productType !== PAYEE_PRODUCT_TYPE_TICKET) return ""
    //TODO - (figure out hot to detect this) if the event has passed, return nothing
    if (payeeProduct.eventHasPassed) {
        return ""
    }
    
    return (
        <>
            <button
                onClick={() => setModalOpen(true)}
                className={`button gradient small`}
            >
                Scan Ticket
            </button>
            <Modal
                isOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
                showCloseButton={true}
                className="height-fit-content"
                overlayClassName="flex-center"
                closeOnOverlayClick={true}
            >
            {
                modalOpen ?
                <TicketEntryScanner 
                    payeeProductId={payeeProduct.id}
                    payeeId={payeeId}
                />
                :
                null
            }
            </Modal>
        </>
    )
}

export default TicketProductQuickActionButton
import React from "react"

import {Helmet} from "react-helmet";

const MerchantTermsOfService = () => {
    return (
        <div className="legal">
            <Helmet>
                <title>Merchant Terms of Service - Shopdm Pay </title>
            </Helmet>
            <div>
                <div className="h1">Merchant Terms of Service</div>
                <div>Last updated: 31st October 2024</div>
                <div className="margin-top-1em">
                Hi There! We’re very happy that you want to use Shopdm Pay.  Before you engage with us, we want to make sure you understand how we operate, the commitments that we make to you, and what we expect from you. Please read through this Merchant Terms of Service agreement and reach out to us at <a href="mailto:pay@shopdm.store" target="_blank">pay@shopdm.store</a> if you have any questions.
                </div>
                <div className="h2">1. About this Merchant Terms Of Service Agreement</div>
                <div>
                    <div>
                        This Merchant Terms of Service Agreement <b>(the “Agreement”)</b> is a legal agreement between Dom Software Ltd T/A Shopdm Pay <b>(“we”, “our”, “us”, “Dom Software Ltd” or “Shopdm Pay”)</b>, and you <b>(“you” or “your”)</b>, the merchant <b>(the “Merchant”)</b> who wishes to open a Shopdm Pay Merchant Account <b>(“Merchant Account” or “Shopdm Pay Merchant Account”)</b> and/or uses any of our products or services <b>(“Services”)</b> to accept payments or for any other reason. Merchants may be organized as a sole trader, registered or unregistered business, limited or public company, partnership, or a charity, NGO, cooperative or trust. All of these are included in the reference to 'you'.
                    </div>
                    <div className="margin-top-05em">
                    This Agreement governs your use of Shopdm Pay’s Services. By using any of Shopdm Pay’s Services with a Merchant Account or by opening a Shopdm Pay Merchant Account, you confirm that you accept this Agreement, in its most current form as posted on our Website or App. For avoidance of doubt, this Agreement includes our Privacy Policy, Security Policy, Refund Policy and any specific terms within this document that limit our liability.
                    </div>
                </div>
                <div className="h2">2. Who we are</div>
                <div>
                    <div>Shopdm Pay is a service operated by Dom Software Ltd, a company incorporated and registered in the Commonwealth of Dominica, with company number is 2019/C0074.</div>
                    <div className="margin-top-05em">Our registered office is at Top Floor, 20 Bath Road, Roseau, Dominica. If you have any questions or if you would like to contact us for any other reason, please email us at <a href="mailto:pay@shopdm.store" target="_blank">pay@shopdm.store</a> or call us at +17676120435.</div>
                </div>
                <div className="h2">3. Our Services under this Agreement</div>
                <div>
                    <div>
                        We are a Payment Services Provider and we act as such by developing, hosting and offering our Services to you, to allow you to accept payments from customers <b>(“Consumers”)</b> who wish to pay you via the Internet. Our Services are in compliance with the local laws and regulations. 
                        Our Services consist of all of the products and services we provide now and in the future. At present, our payment-related products include:
                    </div>
                    <div className="margin-top-05em">
                        <div>Our Services consist of all the services we provide now and in the future. At present these include:</div>
                        <ol>
                            <li>Online Invoice Payments</li>
                            <li>In-Person QR Code Payments</li>
                            <li>Hosted Product Payments</li>
                            <li>Integrated Website Payments</li>
                        </ol>
                    </div>
                    <div className="margin-top-05em">We act as a payment facilitator when you use our Services to accept card payments from Consumers for goods and services. Our Services allow you to accept payments from most credit, debit, prepaid, or gift cards <b>(“Card” or “Cards”)</b> bearing the trademarks of MasterCard International Inc. <b>(“MasterCard”)</b> and Visa Inc. <b>(“Visa”)</b> <b>(collectively, the “Networks”)</b>. Shopdm Pay may remove or add Cards that we accept at any time without prior notice.</div>
                    <div className="margin-top-05em">In order to serve in this role, we have entered into agreements with Networks, processors and acquiring banks. The Networks require that any Merchant that processes more than Network-specified or acquiring bank-specified amounts of Card sales enter into an agreement directly with Shopdm Pay’s acquiring banks.</div>
                    <div className="margin-top-05em">Shopdm Pay is party to an agreement with one or more acquiring banks under which those banks as acquirer <b>(“Bank”)</b>, provides payment processing services to Shopdm Pay on your behalf and on behalf of our other sub-merchants. As a condition of providing services to Shopdm Pay on your behalf, Shopdm Pay is required to include certain terms and conditions in its Agreement with you.</div>
                    <div className="margin-top-05em">Both the Bank and the Networks have operating rules which govern the procedures, responsibilities, risks and rights of various parties involved in accepting Cards for payment. Copies of those rules can be found within this agreement. You must comply with these operating rules at all times.The Bank and the Networks each have the right to enforce all the terms and conditions of this Agreement relevant to card processing against you.</div>
                    <div className="margin-top-05em">
                        <div>Before you can use our Services as a Merchant, you must open a Shopdm Pay Merchant Account. In order to open a Merchant Account you must submit the required documentation as part of our Know Your Customer (KYC) process. This will vary based on your legal structure but it will include:</div>
                        <ol>
                            <li>
                                For each director or owner with more than 10% ownership:
                                <ol className="list-style-lower-alpha">
                                    <li>a Passport (or 2 other forms of government ID, if we decide to make an exception) and</li>
                                    <li>a proof of address e.g. driver’s license or utility bill</li>
                                </ol>
                            </li>
                            <li>Your business registration or incorporation documents.</li>
                        </ol>
                    </div>
                    <div className="margin-top-05em">By opening a Merchant Account, you authorize us to act as your agent for the purposes of holding, receiving, and disbursing funds on your behalf. Your authorization permits us to generate an electronic funds transfer or a paper draft to settle the proceeds of each Card transaction that you authorize, less any applicable fees. Your authorization will remain in full force and effect until the closure or termination of your Merchant Account. Our receipt of transaction funds satisfies your Consumer’s obligation to pay you. We will remit to you funds actually received by us on your behalf, less amounts owed to us, subject to any Chargeback provisions withheld or applied.</div>
                </div>
                <div className="h2">4. Balances & Payouts</div>
                <div>
                    <div>When Consumers pay you using our Services, your earnings will be credited to the balance on your Merchant Account <b>(“Balance”)</b>. Your Balance will usually be updated to reflect your new total earnings as soon as a transaction is completed, barring technical issues or internet connection issues. Any Balance you hold in your Shopdm Pay Merchant Account represents an unsecured claim against Shopdm Pay.</div>
                    <div className="margin-top-05em">Shopdm Pay is not a bank. The actual funds associated with any payment to you from a Consumer will be settled via one of Shopdm Pay’s payment processor partners into a bank account held with one of Shopdm Pay’s banking partners. These funds are held separate from Shopdm Pay's corporate funds, and Shopdm Pay will neither use these funds for its operating expenses or any other corporate purposes nor will it voluntarily make these funds available to its creditors in the event of bankruptcy. You will not receive interest or other earnings on the amounts in your Balance. As consideration for using the Services, you irrevocably assign to Shopdm Pay all rights and legal interests to any interest and/or other earnings that may accrue or are attributable to our holding of your Balance in our bank account.</div>
                    <div className="margin-top-05em">Shopdm Pay will pay your Balance into your registered Merchant bank account on a predefined schedule <b>(a “Payout”)</b>. Your Payout schedule will depend on the Services that you are using under your Merchant Account. At present, Payouts are done on the 1st, 8th, 15th and 22nd of every month for Online Invoice Payments and In-Person QR Code Payments. Payouts are usually done on time and according to schedule, but they may be delayed due to unforeseen circumstances such as longer-than-usual settlement times on the part of our Bank. Requests for Payouts outside of the predefined schedule are not guaranteed and may incur a fee.</div>
                </div>
                <div className="h2">5. Fees & Pricing</div>
                <div>
                    <div>The following represents our current fee structures and policies. You agree to the following:</div>
                    <ol>
                        <li>At present, we charge a platform fee of roughly 6% for Online Invoice Payments or In-Person QR Code Payments. Our up to date pricing is available on our Pricing page here.</li>
                        <li>Shopdm Pay will apply a platform fee on all payments to you via the Services.</li>
                        <li>When a platform fee is applied to a payment, it may be paid wholly by your Consumer, wholly by you, the Merchant, or it may be paid partially by you and your Consumer. The amount, structure and division of the fee will be determined when your Merchant Account is opened.</li>
                        <li>At all times, our platform fees will be displayed clearly and transparently to you and your Consumers before they pay you via the Services.</li>
                        <li>Our platform fees are not a surcharge. Our platform fees are used to cover the operational costs associated with developing, running and maintaining our business, platform and systems.</li>
                        <li>Shopdm Pay does not charge any additional fees for the Services included in its Fixed-term Partner Plan. Other plans or additional Services, e.g. marketing products, may incur additional fees.</li>
                        <li>You agree to pay any applicable fees associated with your use of the Services.</li>
                        <li>We may not process your transaction(s) or provide any other Services to you until we have received the relevant fees from you.</li>
                        <li>Our fees do not include any fees that your bank, your Consumer’s bank, or any other third party may separately charge.</li>
                        <li>Our consumer fees and merchant fees are exclusive of any taxes, charges, or similar assessments of any nature, including, without limitation, value-added, sales, digital services, stamp, transfer, or withholding taxes, assessable by any jurisdiction or governmental authority <b>(collectively, “Taxes”)</b>.</li>
                        <li>You are responsible for any Taxes which may be due by you because of your use of our Services, and it is your responsibility to collect, report and pay the correct tax to the appropriate tax authority.</li>
                        <li>Shopdm Pay reserves the right to change any information or policy associated with our fees or limits any time and at our sole discretion. When commercially reasonable, we will take reasonable steps to provide notice thirty (30) days in advance. Your use of the Services, after the notification period will constitute your agreement to such changes.</li>
                    </ol>
                </div>
                <div className="h2">6. Chargebacks & Dispute Resolution</div>
                <div>
                    <div>As a Shopdm Pay merchant you agree to properly and satisfactorily deliver goods and services to your Consumers. If you are found to be misrepresenting your products or not delivering on your contracts, you will be in breach of this Agreement and that may be grounds for Termination. Despite your best efforts, it will always be possible for disputes to occur with Consumers or for chargebacks to be initiated by Consumers. You agree to the following terms and dispute resolution mechanisms:</div>
                    <ol>
                        <li>Shopdm Pay does not have any control over, and is not responsible or liable for, the products or services that you accept payment for via its Services. Shopdm Pay’s obligation to you and your Consumers, at all times, will only be to properly run the Services.</li>
                        <li>When you use Shopdm Pay’s Services to accept payments for your goods and/or services from a Consumer you are entering into a contract with that Consumer to deliver those goods and/or services, at the expected quality and by the expected date <b>(the “Merchant Contract”)</b>.</li>
                        <li>You agree to properly and satisfactorily deliver goods and services under any and all of your Merchant Contracts.</li>
                        <li>You agree to, as much as is reasonably practicable, deliver goods and services that fully meet the expectations and requirements established by the Merchant Contract.</li>
                        <li>You are fully responsible and liable for the products or services that you accept payment for via the Services and deliver or fail to deliver under your Merchant Contracts.</li>
                        <li>There may be times when your Consumer may not be the authorized user of the Card or your Consumer may otherwise contest the transaction. In these instances, the amount of a transaction may be reversed or charged back to your Shopdm Pay Merchant Account <b>(a “Chargeback”)</b>. This may occur if the transaction (a) is disputed, (b) is reversed for any reason by the Network, our processor, or a Consumer or its financial institution, (c) was not authorized or we have any reason to believe that the transaction was not authorized, or (d) is allegedly unlawful, suspicious, or in violation of this Agreement.</li>
                        <li>You are fully responsible for any Chargebacks that occur in relation to your Merchant Contracts or your use of the Services and any associated fines or fees. </li>
                        <li>If your use of the Services results in a Chargeback, Shopdm Pay will debit your Balance the full value of the Chargeback, as well as any associated fines or fees <b>(the “Chargeback Debit”)</b>.</li>
                        <li>If you do not have enough funds in your Balance to cover the Chargeback Debit, Shopdm Pay will apply a fee to your Balance and put your balance into negative.</li>
                        <li>You agree to clear any negative balances within three (3) days of the Chargeback Debit being applied.</li>
                        <li>You authorize Shopdm Pay to recover funds from you for the full value of the Chargeback Debit <b>(the “Recovery Authorizations”)</b>. Recovery Authorizations include your authorization of Shopdm Pay to debit, charge, set off against and otherwise recover funds from any connected Merchant Account, Balance, any payment instrument, linked bank account and other account registered in your Merchant Account.</li>
                        <li>Further, in the event that any attempt to recover funds from you should fail, Recovery Authorizations include your grant to Shopdm Pay of new, original authorizations to recover all or less than all of the amount you owe to us. Recovery Authorizations include all authorizations to take the above steps without prior notice to you and whether the obligation is contingent, matured or unmatured. Recovery Authorizations include all authorizations to take the above steps in complete compliance with the Network Rules. You agree that your grant of Recovery Authorizations to us has the same legal effect as if you had signed paperwork containing the same terms.</li>
                        <li>
                            You expressly acknowledge and agree that:
                            <ol className="list-style-lower-alpha">
                                <li>even though a card purchase or payment is made to you, and facilitated by us via the Services, this payment is conditional and subject to reversals and payment adjustments ie. Chargebacks;</li>
                                <li>neither us nor the Bank decides which transactions are charged back and neither party has any control over the ultimate resolution of the Chargebacks;</li>
                                <li>although Shopdm Pay and the Bank may attempt to reverse a Chargeback, they can only do so if allowed by the Network; and</li>
                                <li>in certain circumstances a Chargeback will not be reversed even though you have provided goods and services to your Consumer and are legally entitled to payment.</li>
                            </ol>
                        </li>
                        <li>You agree to assist us when requested, at your expense, to investigate any of your transactions processed through the Services, especially in relation to Chargebacks. To that end, you permit us to share information about a Chargeback with the Consumer, the Consumer’s financial institution, and your financial institution in order to investigate and/or mediate a Chargeback. We will request necessary information from you to contest the Chargeback. We may also use any content you upload through the services to respond to Chargebacks on your behalf. If a Chargeback dispute is not resolved in your favor by the Network or issuing bank or you choose not to contest the Chargeback, we may recover the Chargeback amount and any associated fees as described in this Agreement. You acknowledge that your failure to assist us in a timely manner when investigating a transaction, including providing necessary documentation within 14 days of our request, may result in an irreversible Chargeback.</li>
                        <li>You will be in breach of this Agreement if two (2) or more Chargeback disputes are not resolved in your favor in any six (6) month period.</li>
                        <li>To avoid Chargebacks and maintain customer confidence, Shopdm Pay runs a Dispute Resolution Process as part of the Services to allow you and your Consumers to resolve disputes, before they are escalated to Chargebacks <b>(the “Dispute Resolution Process”)</b>. You agree to fully engage with this Dispute Resolution Process, in a timely manner, and you agree to take every effort to avoid the occurrence of Chargebacks.</li>
                        <li>If you or a Consumer has any concerns in relation to a Merchant Contract then a dispute has arisen <b>(the “Dispute”)</b>. For example, a Dispute may arise if a Consumer is of the opinion that you have not shipped an item after an online purchase or that the item that they have received varies significantly from what you have described, or if a Consumer pays you the wrong amount, does not pay for your goods or services after receiving them or pays you accidentally. When a Dispute arises, you agree to establish contact with the Consumer in the first instance and seek to resolve the Dispute. </li>
                        <li>When Consumers contact you in relation to a Dispute, you agree to acknowledge their contact within 24 hours, to engage with them in a professional and constructive manner and to seek to resolve the Dispute within 3 days.</li>
                        <li>If you and the Consumer are unable to resolve the Dispute within 3 days then either of you may escalate the Dispute to a Claim with Shopdm Pay <b>(the “Claim”)</b>. </li>
                        <li>Upon receiving instructions to initiate the Dispute Resolution Process, Shopdm Pay will first confirm that you and the Consumer were unable to resolve the Dispute. Next, Shopdm Pay will investigate the Claim by collecting information from you and the Consumer. Then Shopdm Pay will review the information provided and seek to determine how to resolve the Claim and in whose favor the Claim will be awarded. This process will constitute the Dispute Resolution Process.</li>
                        <li>Shopdm Pay will, wherever possible, seek to resolve the Claim within 7 days of receiving all requested information from you and the Consumer.</li>
                        <li>If a Claim is awarded in your favor, it may result in further actions being taken to protect you and Shopdm Pay from false claims by the Consumer, including terminating our Agreement with the Consumer.</li>
                        <li>f a Claim is awarded in your Consumer’s favor and if it involves a refund, your Consumer will be refunded an amount not more than the full amount which they had paid to you via our Services under the Merchant Contract.</li>
                        <li>If you do not provide all of the requested information within 14 days of a Claim being filed, then Shopdm Pay will automatically award the Claim in the favor of the Consumer.</li>
                        <li>You will be in breach of this Agreement if four (4) Claims are decided against you, in any six (6) month period. Therefore, you are strongly advised to avoid Disputes whenever possible and to resolve Disputes before they are escalated to Claims, whenever possible.</li>
                    </ol>
                </div>
                <div className="h2">7. Your Eligibility To Use Our Services</div>
                <div>
                    <div>You may only enter into this Agreement if you meet the following criteria and are able to enter into a legally binding contract in the country in which you reside. If any of these conditions is not met, you must not use our Services.</div>
                    <ol>
                        <li>The Merchant must be organized or registered in a country or territory that we support in order to use our Services. At present the only country that we support is the Commonwealth of Dominica.</li>
                        <li>You may not enter into this Agreement or use any of our Services if you are in any jurisdiction that is the target of government sanctions by any national government.</li>
                        <li>You may not enter into this Agreement or use any of our Services if you represent an entity or individual that is the target of government sanctions, blocked or prohibited by any authority or any national government. For example, but not limited to, those applied by the U.S. Department of the Treasury Office of Foreign Assets Control.</li>
                        <li>You must have authority to bind the Merchant. You must confirm that you in fact have authority to bind the Merchant on whose behalf you use our Services, and the Merchant accepts the terms of this Agreement. We may ask you at any time to provide proof of such authority. If you do not provide proof of authority that is acceptable to us, we may close or suspend the Shopdm Pay Merchant Account or Services held by your Merchant or deny you access. This may be in favor of others who can provide proof of such authority.</li>
                        <li>The Merchant may only open one Shopdm Pay Merchant Account and one profile. For clarification, this does not preclude an individual from registering multiple Merchants with us, each with a different Shopdm Pay Merchant Account.</li>
                        <li>You can authorize others to access, use or operate a Shopdm Pay Merchant Account or otherwise use our Services on behalf of a Merchant (but not by or on behalf of an individual) <b>(an “Authorized User”)</b>. You acknowledge that if you provide such access to an Authorized User, we’ll deal with such Authorized User as if they were you for the purposes of this Agreement including any conduct related to a Balance held in the Shopdm Pay Merchant Account or other instructions, such as withdrawing or sending money. You will be bound by anything done by any Authorized User, even if they do something that is outside the scope of the authority you gave them. You acknowledge that we will not perform any verification of the authority of your Authorized Users to act on behalf of your Merchant. You also acknowledge that we may disclose certain information about your profile or Shopdm Pay Merchant Account to any Authorized User and that we are not responsible for your Authorized User’s use of that information. Granting permission to any Authorized User does not relieve you of your responsibilities under this Agreement, including notifying us if your Shopdm Pay Merchant Account has been compromised or if a transaction is suspected to be incorrect or unauthorized.</li>
                        <li>Disputes between you and an Authorized User relating to your Shopdm Pay Merchant Account or our Services are a matter between you and the Authorized User. You recognise that Shopdm Pay is not a party to any claim or dispute between you and your Authorized Users. Because you are bound by the actions of Authorized Users, it’s very important that you choose Authorized Users carefully. We suggest you regularly reassess who can be an Authorized User and regularly review the activity undertaken by Authorized Users.</li>
                    </ol>
                </div>
                <div className="h2">8. How To Safely Use Our Services</div>
                <div>
                    <ol>
                        <li>
                            When accessing your Shopdm Pay Merchant Account or any of the Services, you should do the following, at minimum, to keep yourself safe:
                            <ol className="list-style-lower-alpha">
                                <li>Change your password regularly and ensure that it is not reused for other online accounts.</li>
                                <li>Never share your login details with anyone else.</li>
                                <li>If you suspect that your account may have been accessed by someone else, contact the Shopdm Pay Customer Support Team at <a href="mailto:pay@shopdm.store" target="_blank">pay@shopdm.store</a>. We will never ask you for your password.</li>
                                <li>Always use strong passwords, for example passwords that do not include common words and are composed of uppercase and lowercase letters, as well as numbers, and symbols.</li>
                                <li>Set up 2-step authentication, if applicable.</li>
                                <li>Ensure that your email account is secure. This is because your email account may be used to reset your password. Let the Shopdm Pay Customer Support Team know immediately if your email account becomes compromised.</li>
                                <li>Update the App regularly on your device to the latest available version.</li>
                                <li>Update your device’s browser to the latest available version.</li>
                                <li>Maintain your device’s operating systems with regular security updates provided by the operating system provider.</li>
                                <li>Install and maintain the latest anti-virus software on your devices, where applicable.</li>
                                <li>Avoid phishing and other malicious attacks by ensuring that every time you are entering your Shopdm Pay username, password, phone number, credentials and card information that it is only ever on our official App (downloaded from the iOS or Android store) or if via a browser, at https://pay.shopdm.store. Attackers may send emails and SMS messages to lead you to fake login portals that could phish your login information and 2-step authentication details. Similarly, criminals can advertise malicious adverts on search engines that lead to fake websites that impersonate real, trusted businesses, including Shopdm Pay.</li>
                                <li>If you suspect you have become a victim of a scam, contact the Shopdm Pay Customer Support Team as soon as possible.</li>
                            </ol>
                        </li>
                        <li>
                            You agree to never:
                            <ol className="list-style-lower-alpha">
                                <li>Give your login credentials or 2-step authentication details to anyone.</li>
                                <li>Allow anyone else to access your Shopdm Pay Merchant Account or profile, or watch you accessing it, including by allowing someone else to take remote control of your device.</li>
                                <li>Do anything which may in any way avoid or compromise the 2-step authentication process.</li>
                            </ol>
                        </li>
                        <li>Contact us immediately, if you suspect your Shopdm Pay Merchant Account has been compromised. If you suspect your Shopdm Pay Merchant Account, profile or login credentials have been stolen, lost, or otherwise compromised, you must immediately contact the Shopdm Pay Customer Support Team at <a href="mailto:pay@shopdm.store" target="_blank">pay@shopdm.store</a>.</li>
                        <li>Any delays in notifying us of a compromised account, may affect the security of your account and result in losses that you would be responsible for. You must promptly provide us with any information which we request of you or any reasonable assistance we require from you in order to investigate the incident and to secure your account.</li>
                        <li>You are responsible for ensuring that your device and other relevant technology is configured to access our Services. We cannot guarantee that our Services will be free from bugs or viruses.</li>
                    </ol>
                </div>
                <div className="h2">9. How You Can Use Our Services and Systems</div>
                <div>
                    <div>You agree to only use our Services and your Shopdm Pay Merchant Account in lawful and constructive ways. You agree not to misuse our Services. You agree not to misuse any of our systems, technological or otherwise. In particular, you agree to the following:</div>
                    <ol>
                        <li>You agree not to use our Services for any fraudulent or unlawful purpose.</li>
                        <li>You agree that all information you provide to us must be complete, accurate and truthful at all times. You also agree to update any information that we hold about you, whenever it changes. You agree that we are not responsible for any loss arising out of your failure to provide complete accurate, truthful and up to date information.</li>
                        <li>We may ask you at any time to confirm the accuracy of your information and/or to provide additional supporting documents, and you agree to comply with these requests and to provide such information in a format acceptable to us.</li>
                        <li>
                            You must not misuse our Services by:
                            <ol className="list-style-lower-alpha">
                                <li>Breaching this Agreement, Additional Document, or any other agreement between you and us or any of our partners.</li>
                                <li>Violating any law, statute, ordinance, or regulation for example, those governing financial services, consumer protections, sale of counterfeit goods or false advertising.</li>
                                <li>Acting in a manner that is defamatory, libelous, threatening or harassing.</li>
                                <li>Providing false, inaccurate, or misleading information.</li>
                                <li>Sending or receiving what we believe to be potentially fraudulently gained funds.</li>
                                <li>Participating in any transaction that may be deemed to be fraudulent by any financial or regulatory authority.</li>
                                <li>Acting in a manner that leads us or any regulatory or banking agency to believe that you may be in non-compliance with any applicable anti-money laundering laws and regulations.</li>
                                <li>Refusing to cooperate in an investigation or to provide confirmation of your identity or any information requested by us.</li>
                                <li>Infringing Shopdm Pay’s Intellectual Property.</li>
                                <li>Attempting to “double dip” during a dispute or claim by receiving or attempting to receive funds from both Shopdm Pay and a third party, for example, a Merchant, bank or card issuer for the same transaction.</li>
                                <li>Abusing a card network dispute resolution process.</li>
                                <li>Fraudulently initiating a card network dispute for any reason which is not deemed to be true by both the card network and us, including, but not limited to, claiming that you did not receive goods or services which you received, or claiming that the goods or services received were not at the expected quality when this is untrue, or claiming that you did not agree to an agreement between yourself and us or one of our Merchants or partners, when you did indeed agree to that agreement.</li>
                                <li>Using our Services in a manner that we, a card network or any other electronic funds transfer network believes to be an abuse of the card system or a violation of card association or network rules.</li>
                                <li>Taking any action that may cause us to lose any of the services from our  payment processors, internet service providers, or our other suppliers or service providers.</li>
                                <li>Harassing, threatening or otherwise being abusive towards our employees, agents, or other customers.</li>
                                <li>Using our Services in a manner that results in or may result in complaints to or about Shopdm Pay.</li>
                                <li>Using our Services in a manner that results in or may result in requests by third parties to invalidate payments made to you.</li>
                                <li>Using our Services in a manner that results in or may result in fees, fines, penalties or any other liability or losses to Shopdm Pay, other customers, third parties or you.</li>
                                <li>
                                    Using our Services for the sale of any products or services that fall under the following restricted categories:
                                    <ol className="list-style-lower-roman">
                                        <li>Any illegal activity or goods.</li>
                                        <li>Paraphernalia that may be used for illegal activity.</li>
                                        <li>Casinos, slot machines or online gambling.</li>
                                        <li>Medical or dental equipment.</li>
                                        <li>Unlawful sale of prescription drugs.</li>
                                        <li>Counterfeit or replica items, including but not limited to movies, music, video games, software, and hardware.</li>
                                        <li>Illicit streaming devices.</li>
                                        <li>Apparel, accessories, and consumer goods that do not adhere to legal standards.</li>
                                        <li>Pharmaceuticals, medical devices, tobacco products, and automobile parts.</li>
                                        <li>Marijuana related businesses.</li>
                                        <li>Items designed to inflict bodily harm (e.g., firearms, ammunition, explosives).</li>
                                        <li>Prohibited adult content.</li>
                                        <li>Prostitution.</li>
                                        <li>Private members clubs.</li>
                                        <li>Virtual currency exchanges & businesses engaging in buying and selling virtual currencies, including gift cards, whether digital or physical or any other store of value.</li>
                                        <li>Any products that violate the laws of the Commonwealth of Dominica or any region where Shopdm Pay operates.</li>
                                    </ol>
                                </li>
                                <li>Accessing our Services from a country that we are not permitted to operate in.</li>
                                <li>Using our Services in any way that contravenes any applicable bylaws, rules, and/or regulations set forth by the Networks <b>(“Network Rules”)</b>. The Networks amend their rules and regulations from time to time. Shopdm Pay may be required to change these terms in connection with amendments to the Network Rules. Significant portions of the Network Rules are available to the public at https://www.mastercard.com and https://visa.com. In the event of inconsistency between a Network Rule and this Agreement, and except as otherwise agreed between Shopdm Pay and the Network, the Network Rule shall apply.</li>
                                <li>Circumventing this Agreement or any Additional Document or any of our policies or any determinations we take about your Shopdm Pay Merchant Account such as temporary or indefinite suspensions or other account holds, limitations or restrictions, including, but not limited to, engaging in the following actions: attempting to open a new or additional Shopdm Pay Merchant Account(s) or when another of your accounts has a negative balance or has been restricted, suspended, or otherwise limited or opening new or additional Shopdm Pay Merchant Accounts using information that is not your own (e.g., phone number, name, address, email address, etc.), or using someone else’s Shopdm Pay Merchant Account.</li>
                            </ol>
                        </li>
                        <li>
                            You agree not to misuse our systems by:
                            <ol className="list-style-lower-alpha">
                                <li>Introducing any technologically harmful materials to our systems, including but not limited to, viruses, trojans, worms, logic bombs or other materials which are intended to, or have the effect of negatively affecting our systems.</li>
                                <li>Taking any action that results in an unreasonable or disproportionately large load being imposed on our websites, software, systems (including any networks and servers used to provide any of the Services) operated by us or on our behalf, or attacking our Website, App or API with any type of denial-of-service attack.</li>
                                <li>Using an anonymizing proxy; use any robot, spider, other automatic device, or manual process to monitor or copy our websites without our prior written permission; or use any device, software, or routine to bypass our robot exclusion headers.</li>
                                <li>Interfering, disrupting, or attempting to interfere or to gain unauthorized access to our Website, software, API, systems (including any networks and servers used to provide any of the Services) operated by us or on our behalf, any of the Services or other users’ use of any of the Services.</li>
                            </ol>
                        </li>
                        <li>
                            If you misuse any of our Services or systems or if we suspect any violation of any part of this Agreement or if we have concerns about the security of your Merchant Account or if we believe you may have been involved with any fraudulent, unlawful or unauthorized use of your Merchant Account or the Services or if we are notified of a court order or any other legal process affecting you, we may take several actions to protect Shopdm Pay, its partners, its customers and others. These include but are not limited to the following: 
                            <ol className="list-style-lower-alpha">
                                <li>We may immediately terminate this Agreement.</li>
                                <li>We may immediately close your Shopdm Pay Merchant Account, without penalty to us.</li>
                                <li>We may immediately suspend your Shopdm Pay Merchant Account or your access to our Services for an indeterminate period.</li>
                                <li>We may refuse to provide our Services to you or your businesses or affiliates in the future.</li>
                                <li>We may take legal action against you, including seeking damages and reimbursement for losses and fees.</li>
                                <li>We may contact your bank or credit card issuer, other impacted third parties, or law enforcement about your actions and we may disclose any relevant information about you, your Merchant Account or your use of our Services.</li>
                                <li>We may update any inaccurate information that you have provided to us.</li>
                                <li>We may refuse to process any of your transactions or, if processed, refuse to remit the funds of any transaction to you or any other related Merchant.</li>
                                <li>If the proceeds of any fraudulent transaction have already been remitted to you, we may debit the transaction amount from any balances which you hold with us, and if you do not hold any balance with us from which we can debit for the full amount, you agree to repay the full amount owed to us within 3 days of us requesting payment from you, in the manner that we request it of you.</li>
                                <li>We may hold or freeze any balance that you hold with us, if reasonably needed to protect against any risk which we, at our sole discretion, have determined that we, our partners or customers have been exposed to and the hold may remain in place as long as is reasonably necessary.</li>
                                <li>We may impose limits on the number, amount or value, individually or in aggregate of payments that you can send via our Services.</li>
                                <li>We may share any information about you, your Merchant Account or your use of the Services with any law enforcement, banking partners, or regulatory agencies.</li>
                                <li>We may request additional information from you about yourself, your business, your use of the Services, or your Merchant Account, and you agree to provide any requested information within 3 days.</li>
                                <li>We may report any suspected breach of law, regulation, or criminal statute to the relevant law enforcement authorities, in which case we will cooperate with those authorities, including by disclosing your identity to them.</li>
                                <li>We may limit your access to any of our systems, in any way that we see fit.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className="h2">10. Your Presentation of Shopdm Pay & Partners</div>
                <div>
                    <ol>
                        <li>You agree to accept Shopdm Pay as a payment option at all of your points of sale, unless an exception is mutually agreed between you and Shopdm Pay. For example, if you take Mobanking payments via a Whatsapp business account, you agree to also include your Shopdm Pay payment link in your Whatsapp business account. Or, if you take cash, Mobanking or card payments at a physical location, you agree to accept In-Person QR Code Payments at that location.</li>
                        <li>You must display Shopdm Pay payment methods or marks at least as prominently as any other payment solution you offer, including Mobanking, PayPal, card machines or other options. This includes at least equal or better: logo placement, position within any point of sale, and treatment in terms of payment flow, terms, conditions and restrictions. This applies to any point of sale where you accept Shopdm Pay, including the positioning and display of In-Person QR Code Payments or on online platforms such as your website, WhatsApp business page, Facebook or Instagram or in your marketing materials.</li>
                        <li>Within all of your points of sale, you agree not to try to dissuade or inhibit your customers from using Shopdm Pay or to encourage customers to use an alternate payment method. Your employees must not discourage potential customers from using Shopdm Pay. This includes overt discouragement or implicit discouragement, such as through a lack of interest, poor attitude, or refusal to provide proper customer service.</li>
                        <li>In representations to your customers or in public communications, you must not mischaracterize or disparage any Shopdm Pay Service or exhibit a preference for other payment methods over any Shopdm Pay Service.</li>
                        <li>You must refrain from making any negative statements or defamatory posts about Shopdm Pay, Dom Software Ltd., or any of our Services through any public forum including on news outlets or via social media platforms like Facebook or Instagram.</li>
                        <li>You will ensure that only trained employees and trained Authorized Users use Shopdm Pay via your Merchant Account. You are responsible for ensuring that your employees are well enough trained so that they can correctly use and represent how the system works. We will provide training sessions and training materials as needed. This is to avoid misleading customers by inadvertently communicating false information to them. Any form of misrepresentation of Shopdm Pay, whether intentional or due to negligence is strictly prohibited.</li>
                        <li>Any materials or advertisements featuring our QR codes or other proprietary designs must be approved by us prior to use. Shopdm Pay will provide standardized materials either freely or for sale and we encourage you to use these standardized designs whenever possible. You may also create your own designs but these must be reviewed and approved to ensure they are compliant with the applicable laws and restrictions and to ensure that they properly characterize Shopdm Pay. Unauthorized use of our branding materials and marks is strictly prohibited.</li>
                        <li>Your use of Network logos and marks <b>(“Network Marks”)</b> is governed by the Network Rules. You must familiarize yourself with and comply with these requirements. The Networks are the sole and exclusive owners of their respective Network Marks. You will not contest the ownership of the Network Marks, and any Network may prohibit you from using its Network Marks for any reason and at any time. Shopdm Pay may require you to make modifications to your website or other advertising and signage in order to comply with Network Rules related to the Network Marks.</li>
                    </ol>
                </div>
                <div className="h2">11. Professional Communication</div>
                <div>
                    <ol>
                        <li>You agree to inform Shopdm Pay if there is any significant change to your business, which will lead to a significant change in the size or frequency of your payments. This is because Shopdm Pay is required by anti-money laundering laws to understand the business operations of its Merchants, at all times.</li>
                        <li>You agree to inform Shopdm Pay if there is a change in the directors of your business or in the list of owners  with more than 10% ownership. This is also due to requirements placed on Shopdm Pay by anti-money laundering laws. </li>
                        <li>You agree to inform Shopdm Pay if you intend to add new payment methods to any point of sale where you accept Shopdm Pay. This includes, but is not limited to banks' card machines, Mobanking, Paypal etc. We must be notified of any changes that may lead to a substantial change in the size or frequency of your payments.</li>
                        <li>You agree to inform Shopdm Pay if you have ceased business operations or if you are taking a pause in doing business for longer than 2 months.</li>
                    </ol>
                </div>
                <div className="h2">12. Handling of Processing Errors</div>
                <div>
                    <ol>
                        <li>We will fix processing errors which we discover or are otherwise made aware of.</li>
                        <li>If an error results in your Balance being credited less funds than you are entitled to, we will credit your Balance for the difference.</li>
                        <li>If an error results in your Balance being credited more funds than you are entitled to, we will recover the extra funds from you. You grant us Recovery Authorisations concerning processing errors.</li>
                        <li>If a transaction error occurs during your use of Shopdm Pay, you may notify Shopdm Pay and we will correct transactions that you process incorrectly if you notify us within thirty (30) days of when it first appears on your electronic transaction history. We may need to request additional information from you and your Consumer to determine how to correct the transaction.</li>
                    </ol>
                </div>
                <div className="h2">13. Refunds & Returns</div>
                <div>
                    <ol>
                        <li>By accepting Card transactions through the Services, you agree to process returns of, and provide refunds and adjustments for, your goods or services through your Merchant Account in accordance with this Agreement and the Network Rules.</li>
                        <li>Network Rules require that you disclose your return or cancellation policy to customers at the time of purchase.</li>
                        <li>If your refund policy prohibits returns or is unsatisfactory to the customer, you may still receive a Chargeback relating to such sales. You may not be able to process a partial refund. If your available Balance is insufficient to cover the refund, we may exercise any of our rights set forth in the Recovery Authorizations above, including withdrawing up to the requested refund amount from a payment instrument registered with your Merchant Account or any connected bank account, and crediting it back into your customer’s Card. The Fees are also refunded by Shopdm Pay, so the full purchase amount is always returned to your customer.</li>
                        <li>Shopdm Pay has no obligation to accept any returns of any of your goods or services on your behalf.</li>
                    </ol>
                </div>
                <div className="h2">14. Data Protection</div>
                <div>
                    You agree that we will process your data in accordance with our Privacy Policy which can be found here.
                </div>
                <div className="h2">15. Intellectual Property Rights</div>
                <div>
                    You agree to the following:
                    <ol>
                        <li>All rights, title and interest in and to any software (including without limitation the Website, the API, and any other systems, developer tools, sample source code, and code libraries), data, materials, content and printed and electronic documentation (including any specifications and integration guides) developed, provided or made available by us or our affiliates to you, including content of the Website, and any and all technology and any content created or derived from any of the foregoing <b>(“Shopdm Pay Materials”)</b> and our Services are the exclusive property of Shopdm Pay and its licensors. The Shopdm Pay Materials and Services are protected by intellectual property rights laws and treaties around the world. All such rights are reserved.</li>
                        <li>While you are using our Services, you may use the Shopdm Pay Materials only for your personal use unless you have received written permission from us and solely as necessary to enjoy our Services. Subject to your compliance with this Agreement and your payment of any applicable fees, Shopdm Pay grants you a revocable, non-exclusive, non-sublicensable, non-transferable, royalty-free limited license to access and/or make personal use of the Shopdm Pay Materials and Services. Any use of the Shopdm Pay Materials and Services not specifically permitted under this Agreement is strictly prohibited. The licenses granted by Shopdm Pay terminate if you do not comply with this Agreement or any other service terms.</li>
                        <li>
                            Unless you have received written permission from us, you may not, and may not attempt to, directly or indirectly:
                            <ol className="list-style-lower-alpha">
                                <li>Use any of the Shopdm Pay Materials for any commercial purpose or otherwise infringe our intellectual property rights. </li>
                                <li>Transfer, sublicense, loan, sell, assign, lease, rent, distribute or grant rights in the Services or the Shopdm Pay Materials to any person or entity.</li>
                                <li>Remove, obscure, or alter any notice of any of our trademarks, or other Intellectual Property appearing on or contained within the Services or on any Shopdm Pay Materials.</li>
                                <li>Modify, copy, tamper with or otherwise create derivative works of any software included in the Shopdm Pay Materials; or</li>
                                <li>Reverse engineer, disassemble, or decompile the Shopdm Pay Materials or the Services or apply any other process or procedure to derive the source code of any software included in the Shopdm Pay Materials or as part of the Services.</li>
                            </ol>
                        </li>
                        <li>You may not copy, imitate, modify, or use our trademarks without our prior written consent. You may use HTML logos or other Materials provided by us for the purpose of directing web traffic to the Services. You may not alter, modify, or change these HTML logos in any way, use them in a manner that mischaracterizes Shopdm Pay or the Services or display them in any manner that implies our sponsorship or endorsement. Further, you may not use our trademarks in connection with any product or service that is not our, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Shopdm Pay.</li>
                    </ol>
                </div>
                <div className="h2">16. Termination</div>
                <div>
                    <div>Termination by Us: We may terminate this Agreement, and we may limit, suspend, change, or remove your access to any or all of the Services, including any feature or aspect of the Services, at any time for any reason. If commercially reasonable, we will take reasonable steps to notify you before taking any action that restricts your access to the Services. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of this Agreement, we may terminate this Agreement at any time without notice to you and accordingly we may terminate your access to the Services and your Merchant Account.</div>
                    <div className="margin-top-05em">Termination by You: Subject to any product-specific Agreements between you and us, you may terminate any Service at any time and for any reason by terminating the Service or closing or deleting your Shopdm Pay Merchant Account by emailing us with such a request at <a href="mailto:pay@shopdm.store" target="_blank">pay@shopdm.store</a>. Termination will be effective on the date that your Merchant Account is closed.</div>
                    <div className="margin-top-05em">Effect of Termination. Upon termination, you will not have any further use of or access to the Services or your Shopdm Pay Merchant Account. Subject to applicable law, you will also not have any use of or access to any information you submitted through the Services, and all rights granted under this Agreement will end. Termination does not relieve you of your obligations to pay amounts owed to Shopdm Pay, Merchants, or others. Termination does not revoke any third-party payment authorizations. The following provisions will survive even after this Agreement terminates: Chargebacks & Dispute Resolution, Arbitration Agreement, Our Intellectual Property Rights, Disclaimer of Warranties, Limitation of Liability, Governing Law and the terms in Miscellaneous.</div>
                </div>
                <div className="h2">17. Indemnification</div>
                <div>
                    You shall defend, indemnify and hold harmless Shopdm Pay and its officers, directors, shareholders, and employees, from and against all claims, suits, proceedings, losses, liabilities, and expenses, whether in tort, contract, or otherwise, that arise out of or relate, including but not limited to attorneys’ fees, in whole or in part arising out of or attributable to any breach of this Agreement or any activity by you in relation to your use of the Services.
                </div>
                <div className="h2">18. Arbitration Agreement</div>
                <div>
                    If you are located in the United States, you agree to our Arbitration Agreement, which requires you to resolve disputes between you and Shopdm Pay on an individual basis through arbitration, prohibits you from maintaining or participating in a class action lawsuit, waives your right to a jury trial, and limits the time in which a claim may be brought.
                </div>
                <div className="h2">19. Disclaimer of Warranties</div>
                <div>
                    <div>The Services are provided “as-is” and without any representation or warranty, whether express or implied. Shopdm Pay makes no representation or warranty of any kind whatsoever (other than those implied by statute) with respect to the Services or the content, materials, information and functions we make accessible, and specifically disclaim all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. We do not promise that the Services will be uninterrupted, error-free, or secure.</div>
                    <div className="margin-top-05em">Shopdm Pay does not control or make any warranties regarding the products or services others provide in connection with the Services. In other words, we do not have control over the Merchants or any other third party from which you’re purchasing when using the Services, and we do not promise or imply that the products or services you buy using the Services will work as promised or be safe to use.</div>
                    <div className="margin-top-05em">Some laws limit or prohibit disclaiming the warranties referred to in the previous paragraphs, or impose obligations on us that we can’t eliminate with this Agreement. In those cases, this section (Disclaimer of Warranties) does not restrict, exclude or modify any consumer rights under any applicable law.</div>
                </div>
                <div className="h2">20. Limitation of Liability</div>
                <div>
                    <div>You agree to the following:</div>
                    <div className="margin-top-05em">Shopdm Pay shall in no event be liable to you or to any third party, whether in contract, warranty, tort (including negligence) or otherwise, for lost profits or any special, indirect, incidental, punitive, exemplary, liquidated or consequential damages (including without limitation damages for loss of data or loss of business, profit, revenue, goodwill or opportunity) arising out of or in connection with our websites, software, systems (including any networks and servers used to provide any of the Services) operated by us or on our behalf, any of the Services, or this Agreement (however arising, including negligence - even if Shopdm Pay has been advised of the possibility of such damages), unless and to the extent prohibited by law.</div>
                    <div className="margin-top-05em">In particular, Shopdm Pay will not be liable to you to any third party for any failure to perform our obligations under this Agreement due to a Force Majeure Event. A “Force Majeure Event” is any event beyond the control of Shopdm Pay or its affiliates, including a strike or other labor dispute; labor shortage, stoppage or slowdown; supply chain disruption; embargo or blockade; telecommunication breakdown; power outage or shortage; inadequate transportation service; inability or delay in obtaining adequate supplies; weather; earthquake; fire; flood; act of God; riot; civil disorder; civil or government calamity; epidemic; pandemic; state or national health crisis; war; invasion; hostility (whether war is declared or not); terrorism threat or act; Law; or act of a Governmental Authority.</div>
                    <div className="margin-top-05em">In particular, if you have a dispute with any other Shopdm Pay user, Consumer, Merchant or otherwise or any third party with whom you transact via the Services, you release Shopdm Pay from all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. This means that you must use caution when dealing with third parties when using our Services.</div>
                    <div className="margin-top-05em">Notwithstanding anything to the contrary in this Agreement, Shopdm Pay’s liability to you for any cause whatever and regardless of the form of the action, will at all times be limited to the value of the unremitted funds payable to you.</div>
                    <div className="margin-top-05em">You and we agree that the other has relied on the indemnification, disclaimer of warranties and limitation of liability stated above in entering into this Agreement, the indemnification, limitation and disclaimer are essential to the Agreement between you and us under this Agreement, and they will apply to the fullest extent allowed by law.</div>
                    <div className="margin-top-05em">Some laws restrict our ability to disclaim or limit our liability. In those cases, this section does not restrict, exclude or modify any consumer rights under any applicable law.</div>
                </div>
                <div className="h2">21. Our Right to Make Changes</div>
                <div>
                    We may revise the terms in this Agreement from time to time. We will use reasonable efforts to notify you of material changes to this Agreement in advance of their effectiveness, including by posting notice on the applicable Services or providing notice via an email address associated with you. The revised Agreement will be effective on the date stated in the revised Agreement. By using any part of the Services after any revisions become effective, you agree to those changes. If you do not agree with any changes to this Agreement, you must stop using the Services.
                </div>
                <div className="h2">22. Miscellaneous</div>
                <div>
                    <div>You agree to the following:</div>
                    <div className="margin-top-05em">This Agreement is between you and us. Except as expressly provided in this Agreement, no other person shall have any rights to enforce any of its terms. Neither of us will need to get the agreement of any other person to end or make any changes to this Agreement.</div>
                    <div className="margin-top-05em">You may not transfer, assign, mortgage, charge, subcontract, declare a trust over or deal in any other manner with any or all your rights and obligations under this Agreement without our prior written consent. We reserve the right to transfer, assign or novate this Agreement or any right or obligation under this Agreement at any time without your consent. This does not affect your rights to close your Merchant Account.</div>
                    <div className="margin-top-05em">Severability. Each of the paragraphs of this Agreement operates separately. If any court or relevant authority decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain in full force and effect.</div>
                    <div className="margin-top-05em">Enforcement. Even if we delay in enforcing this Agreement, we can still enforce it later. If we delay in asking you to do certain things or taking certain action, it will not prevent us from taking steps against you at a later date.</div>
                    <div className="margin-top-05em">Entire Agreement. This Agreement supersedes and extinguishes all previous agreements between you and Shopdm Pay, whether written or oral, relating to its subject matter.</div>
                </div>
                <div className="h2">23. Governing Law</div>
                <div>
                    This Agreement is governed by the laws of The Commonwealth of Dominica. Any dispute between you and us in connection with the Services and/or this Agreement shall be brought in the courts of the Commonwealth of Dominica.
                </div>
            </div>
        </div>
    )
}

export default MerchantTermsOfService
import React, {useCallback} from "react"

import withQRScanModal from "../../hocs/withQRScanModal"
import {INVOICE_STATUS_CANCELLED} from "../../constants/invoices";
import {getInvoiceLink, isFullyPaidInvoice, openReceiptInfo} from "../../utils/invoiceUtils"

const InvoiceQuickActionButton = (props) => {
    const {
        invoice={}, payeeHandleId="", setQRScanLink = () => {
        }
    } = props;
    const {id, receiptIds = [], currentStatus: status} = invoice;
    const isFullyPaid = isFullyPaidInvoice(status);

    const handleButtonClick = useCallback(() => {
        if (!isFullyPaid) {
            setQRScanLink(getInvoiceLink(id, payeeHandleId))
            return;
        }

        if (receiptIds[0]) {
            openReceiptInfo(receiptIds[0], payeeHandleId);
        }
    }, [id, isFullyPaid, receiptIds, payeeHandleId, setQRScanLink]);

    if (invoice.currentStatus === INVOICE_STATUS_CANCELLED) {
        return "";
    }

    return (
        <button
            onClick={handleButtonClick}
            className={`button ${isFullyPaid ? 'secondary' : 'gradient' } small`}
        >
            {isFullyPaid ? 'View receipt' : 'Send Pay Link'}
        </button>
    )
}

export default withQRScanModal(InvoiceQuickActionButton)
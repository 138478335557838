import React from "react";
import {convertLinksToAnchors} from "../../utils/stringUtils"

const TextDisplayFormatter = ({children}) => {
    if (!children || typeof children !== "string") return ""
    const lines = children.split("\\n").map(line => line.trim())
    return (
        <div>
            {
                lines.map(line => <>{convertLinksToAnchors(line)}<br/></>)
            }
        </div>
    )
}

export default TextDisplayFormatter
import React, {useEffect} from "react"
import Icon from "../../../components/Icon"

import PayeeProductTable from "../../../containers/PayeeProductTable"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../../actions"

import {Helmet} from "react-helmet";
import { useOutletContext } from 'react-router-dom';


const PayeeProducts = ({payeeProducts, payees, actions}) => {
    const {payeeId} = useOutletContext()

    const savePayeeProducts = async () => {
        actions.fetchSavePayeeProductsForPayee(payeeId)
    }
    //save the payee products when the page loads and whenever the payeeId changes
    useEffect(() => {
        savePayeeProducts()
    }, [payeeId])

    const payee = payees.payeesById[payeeId]
    if (!payee) return ""
    //filter to only this payee's payeeProducts
    const payeeProductsArray = Object.values(payeeProducts.payeeProductsById)
                                    .filter(payeeProduct => payeeProduct.payeeId === payeeId)
    return (
        <div>
            <Helmet>
                <title>Products - Shopdm Pay </title>
            </Helmet>
            <div className="h1 icon-left"><Icon icon="shopping-cart"/> Products</div>
            <div className="margin-top-1em">
                <PayeeProductTable 
                    payeeProductsArray={payeeProductsArray}
                    title="Products"
                    payeeHandleId={payee.handleId}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    payeeProducts: state.payeeProducts,
    payees: state.payees
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PayeeProducts)
import React from "react"
import { MuiTelInput} from 'mui-tel-input'
import styles from "./PhoneInput.module.css"


const PhoneInput = ({value="", onChange=()=>{}, error=false}) => {
    const handleChange = (phoneNumber, info) => {
        phoneNumber = phoneNumber.replaceAll(" ", "")
        onChange(phoneNumber, info)
    } 
    return (
        
        <MuiTelInput 
            className={styles.container} 
            value={value} 
            onChange={handleChange} 
            sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  border: `1px solid ${error ? "var(--dark-error-red)" : "var(--grey)"}` ,

                  ':hover': {
                    border: '0.5px solid var(--grey);',
                  },
                  ':focus-within': { border: '2px solid black !important' }
                },

                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                }
              }}
            
        />
    )
}

export default PhoneInput
import React, {useState, useEffect} from "react"
import {useParams, Navigate} from "react-router-dom"

import FacCardPaymentModal from "../../containers/FacCardPaymentModal"
import NotFound from "../../containers/NotFound";
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal";
import AvatarIcon from "../../components/AvatarIcon";

import {Helmet} from "react-helmet";

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import styles from "./Invoice.module.css"
import {DEBIT_OR_CREDIT_CARD_ID, PAYMENT_OBJECT_TYPE_INVOICE} from "../../constants/payment"
import {INVOICE_STATUS_CANCELLED, INVOICE_STATUS_PAID} from "../../constants/invoices"
const Invoice = ({invoices, payees, user, device, payers, actions}) => {
    const params = useParams()
    const {invoiceId, payeeHandleId} = params

    //listen to invoice indicated by the invoiceId from the url params
    const [invoiceListener, setInvoiceListener] = useState(()=>()=>{})
    //listen to invoice indicated by the invoiceId from the url params
    const [invoiceLoaded, setInvoiceLoaded] = useState(null)
    //listen to the invoice anytime the invoiceId changes
    const listenToInvoice = async () => {
        actions.toggleLoading(true)
        //listen to invoice but also load it directly for speed
        actions.fetchSaveInvoice(invoiceId, () => setInvoiceLoaded(true))
        const newInvoiceListener = await actions.fetchSubscribeToInvoice(invoiceId, () => setInvoiceLoaded(true))
        setInvoiceListener(() => newInvoiceListener)
    }
    useEffect(() => {
        if (invoiceId) listenToInvoice()
        return () => {
            if (typeof invoiceListener === "function") invoiceListener()
        }
    }, [invoiceId])
    const payee = payees.payeesById[payees.payeeIdByHandleId[payeeHandleId]]
    //listen to payee indicated by the payeeHandleId from the url params
    const [payeeListener, setPayeeListener] = useState(()=>()=>{})
    //track loading of payee to know if payee does not exist or is just not yet loaded
    const [payeeLoaded, setPayeeLoaded] = useState(null)
    //listen to they payee anytime the payee handle id changes
    const listenToPayee = async () => {
        //listen to payee by also load it directly for speed
        actions.fetchSaveMerchantPayeeByHandleId(payeeHandleId, () => setPayeeLoaded(true))
        const newPayeeListener = await actions.fetchSubscribeToMerchantPayeeByHandleId(payeeHandleId, () => setPayeeLoaded(true))
        setPayeeListener(() => newPayeeListener)
    }
    useEffect(() => {
        if (payeeHandleId) listenToPayee()
        return () => {
            if (typeof payeeListener === "function") payeeListener()
        }
    }, [payeeHandleId])
    useEffect(() => {
        if (invoiceLoaded && payeeLoaded) {
            actions.toggleLoading(false)
        }
    }, [invoiceLoaded, payeeLoaded])
    useEffect(() => {
        return () => {
            actions.toggleLoading(false)
        }
    }, [])
    const invoice = invoices.invoicesById[invoiceId]
    const invoiceRetrieved = Boolean(invoice)
    //Once we have loaded the invoice and confirmed that it exists
    //1. add an invoice view
    //2. store the state of it's receiptTds array, when it was loaded
    const receiptIds = invoice && invoice.receiptIds ? invoice.receiptIds : []
    const [receiptIdsAtLoad, setReceiptIdsAtLoad] = useState(null) 
    useEffect(() => {
        if (invoiceLoaded && invoiceRetrieved && device.id){
            actions.fetchViewInvoice(invoiceId)
            setReceiptIdsAtLoad(invoice && invoice.receiptIds ? invoice.receiptIds : [])
        }
    }, [invoiceLoaded, invoiceRetrieved, invoiceId, device.id])
    //handle card payments 
    const [cardPaymentModalOpen, setCardPaymentModalOpen] = useState(false)
    
    //redirect from the invoice page to the receipt page if
    //  1. the invoice has the current status of PAID
    //  2. the invoice's receiptIds changes to include another receipt. This captures both PAID and PARTIALLY_PAID situations
    const [redirectToReceiptId, setRedirectToRecieptId] = useState(null)
    useEffect(() => {
        if (
            (
                receiptIdsAtLoad && //invoice has been loaded and receipt ids at load tracked
                receiptIds.length > receiptIdsAtLoad.length
            ) ||
            (invoice && invoice.currentStatus === INVOICE_STATUS_PAID)
        ){
            setRedirectToRecieptId([...receiptIds].pop())
        }
    }, [receiptIds.length])
    if (redirectToReceiptId) return <Navigate replace to={`/${payeeHandleId}/r/${redirectToReceiptId}`}/>
    if (invoiceLoaded && !invoice) return <NotFound />
    if (payeeLoaded && !payee) return <NotFound />
    if (invoice && invoice.currentStatus === INVOICE_STATUS_CANCELLED) return <NotFound />
    //if the payee and invoice have not yet been loaded, and one or both is falsy
    if (!invoice || !payee) return ""
    if (!payee.isActive) return <div>payee is not active</div>
    const {totalXcd, senderFeeXcd, totalPlusFeesXcd, reason, reference, invoiceNumber, billToCustomer={}} = invoice
    //retrieve the payerId of the last used payer 
    const {phoneNumbers=[], payerIdsByPhoneNumber} = payers
    const lastUsedPhoneNumber = phoneNumbers && phoneNumbers.length > 0? phoneNumbers[phoneNumbers.length - 1] : null;
    const lastUsedPayerId = lastUsedPhoneNumber ? payerIdsByPhoneNumber[lastUsedPhoneNumber] : "";
    return (
        <div>
            <Helmet>
                <title>{payee.name}'s invoice on Shopdm Pay </title>
            </Helmet>
            <div className="margin-auto  width-fit-content">
                <div className="card display-width-400px card-shadow">
                    <div className="flex-center"><AvatarIcon name={payee.name} imageUrl={payee.logoImageUrlMed}/></div> 
                    <div className="h2 margin-bottom-05em text-align-center">Pay {payee.name}</div>
                    <div className="amount big">EC $<AmountSmallAfterDecimal amount={totalXcd}/></div>
                    {Number(senderFeeXcd) > 0 ? <div>Processing Fee: <span className="font-weight-600 amount">EC $<AmountSmallAfterDecimal amount={senderFeeXcd}/></span></div> : null}
                    <div className="label margin-top-05em">For</div>
                    <div className={`${styles.reason} font-size-14px`}>{reason}</div>
                    {invoiceNumber ? <div className="">Invoice {invoiceNumber}</div> : null }
                    <button 
                        className="button gradient width-100pct margin-top-1em"
                        onClick={() => setCardPaymentModalOpen(true)}
                    >
                        Pay Now
                    </button>
                </div>
                <div className="margin-top-1em card display-width-400px card-shadow font-size-14px color-grey">
                    <div>{payee.name} uses Shopdm Pay to accept card payments securely online.</div>
                    <div  className="margin-top-05em">Shopdm Pay is a product of Dom Software Ltd, a company registered in the Commonwealth of Dominica (No. 2019/C0074)</div>
                </div>
            </div>
            {
                cardPaymentModalOpen && invoice && invoice.ready ?
                <FacCardPaymentModal 
                    isOpen={Boolean(cardPaymentModalOpen)}
                    closeModal={() => setCardPaymentModalOpen(false)} 
                    onSuccess={() => actions.toggleLoading(true)}
                    amount2DecimalPlaces={totalPlusFeesXcd}
                    userId={user.id} //provide user id if payer is logged in
                    userName={`${user.firstName} ${user.lastName}`} //provider user name if payer is logged in
                    email={billToCustomer.email}
                    phoneNumber={billToCustomer.phoneNumber}
                    address={billToCustomer.address}
                    payerId={lastUsedPayerId}
                    deviceId={device.id}
                    objectId={invoice.id}
                    objectType={PAYMENT_OBJECT_TYPE_INVOICE}
                    reference={reference}
                    paymentProviderId={DEBIT_OR_CREDIT_CARD_ID}
                />
                :
                null
            }
        </div>
    )
}

const mapStateToProps = state => ({
    invoices: state.invoices,
    payees: state.payees,
    user: state.user,
    device: state.device,
    payers: state.payers
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Invoice)
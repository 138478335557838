export const COUNTRY_CODES = {
  "AD": {
      "code": "AD",
      "name": "Andorra"
  },
  "AE": {
      "code": "AE",
      "name": "United Arab Emirates",
      "noZipCode": true
  },
  "AF": {
      "code": "AF",
      "name": "Afghanistan"
  },
  "AG": {
      "code": "AG",
      "name": "Antigua and Barbuda",
      "noZipCode": true
  },
  "AI": {
      "code": "AI",
      "name": "Anguilla"
  },
  "AL": {
      "code": "AL",
      "name": "Albania"
  },
  "AM": {
      "code": "AM",
      "name": "Armenia"
  },
  "AO": {
      "code": "AO",
      "name": "Angola",
      "noZipCode": true
  },
  "AQ": {
      "code": "AQ",
      "name": "Antarctica",
      "noZipCode": true
  },
  "AR": {
      "code": "AR",
      "name": "Argentina"
  },
  "AS": {
      "code": "AS",
      "name": "American Samoa"
  },
  "AT": {
      "code": "AT",
      "name": "Austria"
  },
  "AU": {
      "code": "AU",
      "name": "Australia"
  },
  "AW": {
      "code": "AW",
      "name": "Aruba",
      "noZipCode": true
  },
  "AX": {
      "code": "AX",
      "name": "Åland Islands"
  },
  "AZ": {
      "code": "AZ",
      "name": "Azerbaijan"
  },
  "BA": {
      "code": "BA",
      "name": "Bosnia and Herzegovina"
  },
  "BB": {
      "code": "BB",
      "name": "Barbados"
  },
  "BD": {
      "code": "BD",
      "name": "Bangladesh"
  },
  "BE": {
      "code": "BE",
      "name": "Belgium"
  },
  "BF": {
      "code": "BF",
      "name": "Burkina Faso",
      "noZipCode": true
  },
  "BG": {
      "code": "BG",
      "name": "Bulgaria"
  },
  "BH": {
      "code": "BH",
      "name": "Bahrain"
  },
  "BI": {
      "code": "BI",
      "name": "Burundi",
      "noZipCode": true
  },
  "BJ": {
      "code": "BJ",
      "name": "Benin",
      "noZipCode": true
  },
  "BL": {
      "code": "BL",
      "name": "St. Barthélemy"
  },
  "BM": {
      "code": "BM",
      "name": "Bermuda"
  },
  "BN": {
      "code": "BN",
      "name": "Brunei Darussalam"
  },
  "BO": {
      "code": "BO",
      "name": "Bolivia, Plurinational State of"
  },
  "BQ": {
      "code": "BQ",
      "name": "Bonaire, Sint Eustatius and Saba"
  },
  "BR": {
      "code": "BR",
      "name": "Brazil"
  },
  "BS": {
      "code": "BS",
      "name": "Bahamas",
      "noZipCode": true
  },
  "BT": {
      "code": "BT",
      "name": "Bhutan"
  },
  "BV": {
      "code": "BV",
      "name": "Bouvet Island"
  },
  "BW": {
      "code": "BW",
      "name": "Botswana",
      "noZipCode": true
  },
  "BY": {
      "code": "BY",
      "name": "Belarus"
  },
  "BZ": {
      "code": "BZ",
      "name": "Belize",
      "noZipCode": true
  },
  "CA": {
      "code": "CA",
      "name": "Canada"
  },
  "CC": {
      "code": "CC",
      "name": "Cocos (Keeling) Islands"
  },
  "CD": {
      "code": "CD",
      "name": "Congo, Democratic Republic of the",
      "noZipCode": true
  },
  "CF": {
      "code": "CF",
      "name": "Central African Republic",
      "noZipCode": true
  },
  "CG": {
      "code": "CG",
      "name": "Congo",
      "noZipCode": true
  },
  "CH": {
      "code": "CH",
      "name": "Switzerland"
  },
  "CI": {
      "code": "CI",
      "name": "Côte d'Ivoire",
      "noZipCode": true
  },
  "CK": {
      "code": "CK",
      "name": "Cook Islands",
      "noZipCode": true
  },
  "CL": {
      "code": "CL",
      "name": "Chile"
  },
  "CM": {
      "code": "CM",
      "name": "Cameroon",
      "noZipCode": true
  },
  "CN": {
      "code": "CN",
      "name": "China"
  },
  "CO": {
      "code": "CO",
      "name": "Colombia"
  },
  "CR": {
      "code": "CR",
      "name": "Costa Rica"
  },
  "CU": {
      "code": "CU",
      "name": "Cuba"
  },
  "CV": {
      "code": "CV",
      "name": "Cabo Verde"
  },
  "CW": {
      "code": "CW",
      "name": "Curaçao"
  },
  "CX": {
      "code": "CX",
      "name": "Christmas Island"
  },
  "CY": {
      "code": "CY",
      "name": "Cyprus"
  },
  "CZ": {
      "code": "CZ",
      "name": "Czechia"
  },
  "DE": {
      "code": "DE",
      "name": "Germany"
  },
  "DJ": {
      "code": "DJ",
      "name": "Djibouti",
      "noZipCode": true
  },
  "DK": {
      "code": "DK",
      "name": "Denmark"
  },
  "DM": {
      "code": "DM",
      "name": "Dominica",
      "noZipCode": true
  },
  "DO": {
      "code": "DO",
      "name": "Dominican Republic"
  },
  "DZ": {
      "code": "DZ",
      "name": "Algeria"
  },
  "EC": {
      "code": "EC",
      "name": "Ecuador"
  },
  "EE": {
      "code": "EE",
      "name": "Estonia"
  },
  "EG": {
      "code": "EG",
      "name": "Egypt"
  },
  "EH": {
      "code": "EH",
      "name": "Western Sahara"
  },
  "ER": {
      "code": "ER",
      "name": "Eritrea",
      "noZipCode": true
  },
  "ES": {
      "code": "ES",
      "name": "Spain"
  },
  "ET": {
      "code": "ET",
      "name": "Ethiopia"
  },
  "FI": {
      "code": "FI",
      "name": "Finland"
  },
  "FJ": {
      "code": "FJ",
      "name": "Fiji",
      "noZipCode": true
  },
  "FK": {
      "code": "FK",
      "name": "Falkland Islands (Malvinas)"
  },
  "FM": {
      "code": "FM",
      "name": "Micronesia, Federated States of"
  },
  "FO": {
      "code": "FO",
      "name": "Faroe Islands"
  },
  "FR": {
      "code": "FR",
      "name": "France"
  },
  "GA": {
      "code": "GA",
      "name": "Gabon"
  },
  "GB": {
      "code": "GB",
      "name": "United Kingdom of Great Britain and Northern Ireland"
  },
  "GD": {
      "code": "GD",
      "name": "Grenada",
      "noZipCode": true
  },
  "GE": {
      "code": "GE",
      "name": "Georgia"
  },
  "GF": {
      "code": "GF",
      "name": "French Guiana"
  },
  "GG": {
      "code": "GG",
      "name": "Guernsey"
  },
  "GH": {
      "code": "GH",
      "name": "Ghana",
      "noZipCode": true
  },
  "GI": {
      "code": "GI",
      "name": "Gibraltar"
  },
  "GL": {
      "code": "GL",
      "name": "Greenland"
  },
  "GM": {
      "code": "GM",
      "name": "Gambia",
      "noZipCode": true
  },
  "GN": {
      "code": "GN",
      "name": "Guinea",
      "noZipCode": true
  },
  "GP": {
      "code": "GP",
      "name": "Guadeloupe"
  },
  "GQ": {
      "code": "GQ",
      "name": "Equatorial Guinea",
      "noZipCode": true
  },
  "GR": {
      "code": "GR",
      "name": "Greece"
  },
  "GS": {
      "code": "GS",
      "name": "South Georgia and the South Sandwich Islands"
  },
  "GT": {
      "code": "GT",
      "name": "Guatemala"
  },
  "GU": {
      "code": "GU",
      "name": "Guam"
  },
  "GW": {
      "code": "GW",
      "name": "Guinea-Bissau"
  },
  "GY": {
      "code": "GY",
      "name": "Guyana",
      "noZipCode": true
  },
  "HK": {
      "code": "HK",
      "name": "Hong Kong"
  },
  "HM": {
      "code": "HM",
      "name": "Heard Island and McDonald Islands"
  },
  "HN": {
      "code": "HN",
      "name": "Honduras"
  },
  "HR": {
      "code": "HR",
      "name": "Croatia"
  },
  "HT": {
      "code": "HT",
      "name": "Haiti"
  },
  "HU": {
      "code": "HU",
      "name": "Hungary"
  },
  "ID": {
      "code": "ID",
      "name": "Indonesia"
  },
  "IE": {
      "code": "IE",
      "name": "Ireland"
  },
  "IL": {
      "code": "IL",
      "name": "Israel"
  },
  "IM": {
      "code": "IM",
      "name": "Isle of Man"
  },
  "IN": {
      "code": "IN",
      "name": "India"
  },
  "IO": {
      "code": "IO",
      "name": "British Indian Ocean Territory"
  },
  "IQ": {
      "code": "IQ",
      "name": "Iraq"
  },
  "IR": {
      "code": "IR",
      "name": "Iran, Islamic Republic of"
  },
  "IS": {
      "code": "IS",
      "name": "Iceland"
  },
  "IT": {
      "code": "IT",
      "name": "Italy"
  },
  "JE": {
      "code": "JE",
      "name": "Jersey"
  },
  "JM": {
      "code": "JM",
      "name": "Jamaica",
      "noZipCode": true
  },
  "JO": {
      "code": "JO",
      "name": "Jordan"
  },
  "JP": {
      "code": "JP",
      "name": "Japan"
  },
  "KE": {
      "code": "KE",
      "name": "Kenya",
      "noZipCode": true
  },
  "KG": {
      "code": "KG",
      "name": "Kyrgyzstan"
  },
  "KH": {
      "code": "KH",
      "name": "Cambodia"
  },
  "KI": {
      "code": "KI",
      "name": "Kiribati",
      "noZipCode": true
  },
  "KM": {
      "code": "KM",
      "name": "Comoros",
      "noZipCode": true
  },
  "KN": {
      "code": "KN",
      "name": "St. Kitts and Nevis",
      "noZipCode": true
  },
  "KP": {
      "code": "KP",
      "name": "Korea, Democratic People's Republic of"
  },
  "KR": {
      "code": "KR",
      "name": "Korea, Republic of"
  },
  "KW": {
      "code": "KW",
      "name": "Kuwait"
  },
  "KY": {
      "code": "KY",
      "name": "Cayman Islands"
  },
  "KZ": {
      "code": "KZ",
      "name": "Kazakhstan"
  },
  "LA": {
      "code": "LA",
      "name": "Lao People's Democratic Republic"
  },
  "LB": {
      "code": "LB",
      "name": "Lebanon"
  },
  "LC": {
      "code": "LC",
      "name": "St. Lucia",
      "noZipCode": true
  },
  "LI": {
      "code": "LI",
      "name": "Liechtenstein"
  },
  "LK": {
      "code": "LK",
      "name": "Sri Lanka"
  },
  "LR": {
      "code": "LR",
      "name": "Liberia"
  },
  "LS": {
      "code": "LS",
      "name": "Lesotho"
  },
  "LT": {
      "code": "LT",
      "name": "Lithuania"
  },
  "LU": {
      "code": "LU",
      "name": "Luxembourg"
  },
  "LV": {
      "code": "LV",
      "name": "Latvia"
  },
  "LY": {
      "code": "LY",
      "name": "Libya"
  },
  "MA": {
      "code": "MA",
      "name": "Morocco"
  },
  "MC": {
      "code": "MC",
      "name": "Monaco"
  },
  "MD": {
      "code": "MD",
      "name": "Moldova, Republic of"
  },
  "ME": {
      "code": "ME",
      "name": "Montenegro"
  },
  "MF": {
      "code": "MF",
      "name": "St. Martin (French part)"
  },
  "MG": {
      "code": "MG",
      "name": "Madagascar"
  },
  "MH": {
      "code": "MH",
      "name": "Marshall Islands"
  },
  "MK": {
      "code": "MK",
      "name": "North Macedonia"
  },
  "ML": {
      "code": "ML",
      "name": "Mali",
      "noZipCode": true
  },
  "MM": {
      "code": "MM",
      "name": "Myanmar"
  },
  "MN": {
      "code": "MN",
      "name": "Mongolia"
  },
  "MO": {
      "code": "MO",
      "name": "Macao",
      "noZipCode": true
  },
  "MP": {
      "code": "MP",
      "name": "Northern Mariana Islands"
  },
  "MQ": {
      "code": "MQ",
      "name": "Martinique"
  },
  "MR": {
      "code": "MR",
      "name": "Mauritania",
      "noZipCode": true
  },
  "MS": {
      "code": "MS",
      "name": "Montserrat",
      "noZipCode": true
  },
  "MT": {
      "code": "MT",
      "name": "Malta"
  },
  "MU": {
      "code": "MU",
      "name": "Mauritius"
  },
  "MV": {
      "code": "MV",
      "name": "Maldives"
  },
  "MW": {
      "code": "MW",
      "name": "Malawi",
      "noZipCode": true
  },
  "MX": {
      "code": "MX",
      "name": "Mexico"
  },
  "MY": {
      "code": "MY",
      "name": "Malaysia"
  },
  "MZ": {
      "code": "MZ",
      "name": "Mozambique"
  },
  "NA": {
      "code": "NA",
      "name": "Namibia"
  },
  "NC": {
      "code": "NC",
      "name": "New Caledonia"
  },
  "NE": {
      "code": "NE",
      "name": "Niger"
  },
  "NF": {
      "code": "NF",
      "name": "Norfolk Island"
  },
  "NG": {
      "code": "NG",
      "name": "Nigeria"
  },
  "NI": {
      "code": "NI",
      "name": "Nicaragua"
  },
  "NL": {
      "code": "NL",
      "name": "Netherlands"
  },
  "NO": {
      "code": "NO",
      "name": "Norway"
  },
  "NP": {
      "code": "NP",
      "name": "Nepal"
  },
  "NR": {
      "code": "NR",
      "name": "Nauru",
      "noZipCode": true
  },
  "NU": {
      "code": "NU",
      "name": "Niue",
      "noZipCode": true
  },
  "NZ": {
      "code": "NZ",
      "name": "New Zealand"
  },
  "OM": {
      "code": "OM",
      "name": "Oman"
  },
  "PA": {
      "code": "PA",
      "name": "Panama"
  },
  "PE": {
      "code": "PE",
      "name": "Peru"
  },
  "PF": {
      "code": "PF",
      "name": "French Polynesia"
  },
  "PG": {
      "code": "PG",
      "name": "Papua New Guinea"
  },
  "PH": {
      "code": "PH",
      "name": "Philippines"
  },
  "PK": {
      "code": "PK",
      "name": "Pakistan"
  },
  "PL": {
      "code": "PL",
      "name": "Poland"
  },
  "PM": {
      "code": "PM",
      "name": "Saint Pierre and Miquelon"
  },
  "PN": {
      "code": "PN",
      "name": "Pitcairn"
  },
  "PR": {
      "code": "PR",
      "name": "Puerto Rico"
  },
  "PT": {
      "code": "PT",
      "name": "Portugal"
  },
  "PW": {
      "code": "PW",
      "name": "Palau"
  },
  "PY": {
      "code": "PY",
      "name": "Paraguay"
  },
  "QA": {
      "code": "QA",
      "name": "Qatar",
      "noZipCode": true
  },
  "RE": {
      "code": "RE",
      "name": "Réunion"
  },
  "RO": {
      "code": "RO",
      "name": "Romania"
  },
  "RS": {
      "code": "RS",
      "name": "Serbia"
  },
  "RU": {
      "code": "RU",
      "name": "Russian Federation"
  },
  "RW": {
      "code": "RW",
      "name": "Rwanda",
      "noZipCode": true
  },
  "SA": {
      "code": "SA",
      "name": "Saudi Arabia"
  },
  "SB": {
      "code": "SB",
      "name": "Solomon Islands",
      "noZipCode": true
  },
  "SC": {
      "code": "SC",
      "name": "Seychelles",
      "noZipCode": true
  },
  "SD": {
      "code": "SD",
      "name": "Sudan"
  },
  "SE": {
      "code": "SE",
      "name": "Sweden"
  },
  "SG": {
      "code": "SG",
      "name": "Singapore"
  },
  "SH": {
      "code": "SH",
      "name": "Saint Helena, Ascension and Tristan da Cunha"
  },
  "SI": {
      "code": "SI",
      "name": "Slovenia"
  },
  "SJ": {
      "code": "SJ",
      "name": "Svalbard and Jan Mayen"
  },
  "SK": {
      "code": "SK",
      "name": "Slovakia"
  },
  "SL": {
      "code": "SL",
      "name": "Sierra Leone",
      "noZipCode": true
  },
  "SM": {
      "code": "SM",
      "name": "San Marino"
  },
  "SN": {
      "code": "SN",
      "name": "Senegal"
  },
  "SO": {
      "code": "SO",
      "name": "Somalia",
      "noZipCode": true
  },
  "SR": {
      "code": "SR",
      "name": "Suriname",
      "noZipCode": true
  },
  "SS": {
      "code": "SS",
      "name": "South Sudan"
  },
  "ST": {
      "code": "ST",
      "name": "Sao Tome and Principe",
      "noZipCode": true
  },
  "SV": {
      "code": "SV",
      "name": "El Salvador"
  },
  "SX": {
      "code": "SX",
      "name": "Sint Maarten (Dutch part)"
  },
  "SY": {
      "code": "SY",
      "name": "Syrian Arab Republic",
      "noZipCode": true
  },
  "SZ": {
      "code": "SZ",
      "name": "Eswatini"
  },
  "TC": {
      "code": "TC",
      "name": "Turks and Caicos Islands"
  },
  "TD": {
      "code": "TD",
      "name": "Chad"
  },
  "TF": {
      "code": "TF",
      "name": "French Southern Territories"
  },
  "TG": {
      "code": "TG",
      "name": "Togo"
  },
  "TH": {
      "code": "TH",
      "name": "Thailand"
  },
  "TJ": {
      "code": "TJ",
      "name": "Tajikistan"
  },
  "TK": {
      "code": "TK",
      "name": "Tokelau",
      "noZipCode": true
  },
  "TL": {
      "code": "TL",
      "name": "Timor-Leste"
  },
  "TM": {
      "code": "TM",
      "name": "Turkmenistan"
  },
  "TN": {
      "code": "TN",
      "name": "Tunisia"
  },
  "TO": {
      "code": "TO",
      "name": "Tonga",
      "noZipCode": true
  },
  "TR": {
      "code": "TR",
      "name": "Türkiye"
  },
  "TT": {
      "code": "TT",
      "name": "Trinidad and Tobago",
      "noZipCode": true
  },
  "TV": {
      "code": "TV",
      "name": "Tuvalu",
      "noZipCode": true
  },
  "TZ": {
      "code": "TZ",
      "name": "Tanzania, United Republic of",
      "noZipCode": true
  },
  "UA": {
      "code": "UA",
      "name": "Ukraine"
  },
  "UG": {
      "code": "UG",
      "name": "Uganda",
      "noZipCode": true
  },
  "UM": {
      "code": "UM",
      "name": "United States Minor Outlying Islands"
  },
  "US": {
      "code": "US",
      "name": "United States of America"
  },
  "UY": {
      "code": "UY",
      "name": "Uruguay"
  },
  "UZ": {
      "code": "UZ",
      "name": "Uzbekistan"
  },
  "VA": {
      "code": "VA",
      "name": "Holy See"
  },
  "VC": {
      "code": "VC",
      "name": "St. Vincent and the Grenadines"
  },
  "VE": {
      "code": "VE",
      "name": "Venezuela (Bolivarian Republic of)"
  },
  "VG": {
      "code": "VG",
      "name": "Virgin Islands (British)"
  },
  "VI": {
      "code": "VI",
      "name": "Virgin Islands (U.S.)"
  },
  "VN": {
      "code": "VN",
      "name": "Viet Nam"
  },
  "VU": {
      "code": "VU",
      "name": "Vanuatu",
      "noZipCode": true
  },
  "WF": {
      "code": "WF",
      "name": "Wallis and Futuna"
  },
  "WS": {
      "code": "WS",
      "name": "Samoa"
  },
  "YE": {
      "code": "YE",
      "name": "Yemen",
      "noZipCode": true
  },
  "YT": {
      "code": "YT",
      "name": "Mayotte"
  },
  "ZA": {
      "code": "ZA",
      "name": "South Africa"
  },
  "ZM": {
      "code": "ZM",
      "name": "Zambia"
  },
  "ZW": {
      "code": "ZW",
      "name": "Zimbabwe",
      "noZipCode": true
  }
}